import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFetchQuery } from "../utils/fetchData";
import toast from "react-hot-toast";
import base from "../utils/base";

const AllConfrences = () => {

  const [conferences, setConferences] = useState([]);
  const [reqError, setReqError] = useState([]);

  const url = `${base}/api/v1/conference/accepted/all`

  const { data, isSuccess, error } = useFetchQuery(url, 'AllConferences')

  useEffect(() => {
    if (isSuccess && data) {
      setConferences(data.message);
    }
  }, [isSuccess, data, conferences]);

  useEffect(() => {
    if (error && error.response && error.response.data) {
      // toast.error(error.response.data.message, { duration: 3000 });
      setReqError(error.response.data.status);
    }
  }, [error]);


  return (
    <div>
      <div
        className="table-responsive table-scroll"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "700px" }}
      >
        {reqError === 'failed' ?
          <>
            <table className="table table-striped mb-0">
              <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
                <tr>
                  <th scope="col" className="col-md-4">
                    Conference Name
                  </th>
                  <th scope="col" className="col-md-1">
                    Start Date
                  </th>
                  <th scope="col" className="col-md-1">
                    End Date
                  </th>
                  <th scope="col" className="col-md-2">
                    Country
                  </th>
                  <th scope="col" className="col-md-4">
                    External URL
                  </th>
                </tr>
              </thead>
            </table>
            <h3 className="text-center my-2">No Conferences</h3>
          </>
          :
          <table className="table table-striped mb-0">
            <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
              <tr>
                <th scope="col" className="col-md-4">
                  Conference Name
                </th>
                <th scope="col" className="col-md-1">
                  Start Date
                </th>
                <th scope="col" className="col-md-1">
                  End Date
                </th>
                <th scope="col" className="col-md-2">
                  Country
                </th>
                <th scope="col" className="col-md-4">
                  External URL
                </th>
              </tr>
            </thead>
            {conferences.map((data, index) => {
              return <tbody key={index}>
                <tr className="schedule-item">
                  <td>
                    {/* {
                      data.acceptSubmissions ? */}
                    <Link to={`/submission/${data._id}`}>{data.conferenceTitle}</Link>
                    {/* :
                        <Link to="#" onClick={notAccepted}>
                          {data.conferenceTitle}
                        </Link>
                    } */}
                  </td>
                  <td>{data.startDate}</td>
                  <td>{data.endDate}</td>
                  <td>{data.city}, {data.country}</td>
                  <td>
                    <a
                      href={`${data.webPage}`}
                      className="table-url"
                    >
                      {data.webPage}
                    </a>
                  </td>
                </tr>
              </tbody>
            })
            }
          </table>
        }
      </div>
    </div>
  );
};

export default AllConfrences;
