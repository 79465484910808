import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import WelcomeReviewer from "./WelcomeReviewer";
import Footer from "../Components/Footer";
import { getToken } from "../utils/LocalStorage";
import base from "../utils/base";
import toast from "react-hot-toast";
import axios from 'axios';

const ViewPaper = () => {
  const [papers, setPapers] = useState([]);
  const [paperStatus, setPaperStatus] = useState('');
  const [userMapping, setUserMapping] = useState({});
  const [papersReviewed, setPapersReviewed] = useState([]);

  const { id } = useParams();
  const url = `${base}/api/v1/submission/all/papers/user/${id}`;

  useEffect(() => {
    const fetchPapers = async () => {
      try {
        const response = await axios.get(url, {
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: getToken(),
          }
        });
        setPapers(response.data.message);
        setPaperStatus(response.data.status);
      } catch (error) {
        toast.error(error.response?.data?.message || 'Error fetching papers', { duration: 3000 });
      }
    };

    fetchPapers();
  }, [id, url]);

  useEffect(() => {
    const fetchReviewedPapers = async () => {
      try {
        for (const submission of papers) {
          for (const authorId of submission.authors) {
            const url2 = `${base}/api/v1/submission/all/reviewed/${authorId}`;
            const response = await axios.get(url2, {
              headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: getToken(),
              }
            });
            setPapersReviewed(prev => [...prev, ...response.data.message]);
          }
        }
      } catch (error) {
        toast.error(error.response?.data?.message || 'Error fetching reviewed papers', { duration: 3000 });
      }
    };

    if (paperStatus === "success") {
      fetchReviewedPapers();
    }
  }, [paperStatus, papers]);

  console.log(papersReviewed);

  const fetchOrganizerDetails = async (userId) => {
    const organizerHost = `${base}/api/v1/auth/get/id/${userId}`;
    try {
      const response = await axios.get(organizerHost, {
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: getToken(),
        },
      });
      const userData = response.data;
      setUserMapping((prevMapping) => ({
        ...prevMapping,
        [userId]: userData.message.firstName,
      }));
    } catch (error) {
      toast.error(error.response?.data?.message || 'Error fetching organizer details', { duration: 3000 });
    }
  };

  useEffect(() => {
    if (paperStatus === "success") {
      papers.forEach((submission) => {
        submission.authors.forEach((authorId) => {
          fetchOrganizerDetails(authorId);
        });
        fetchOrganizerDetails(submission.author);
      });
    }
  }, [papers, paperStatus]);

  return (
    <div>
      <WelcomeReviewer />
      <div className="section-header text-center justify-content-center pt-4 mt-4">
        <h2>Assigned Papers</h2>
        <p>Here is the list of all assigned papers</p>
      </div>
      <div
        className="table-responsive table-scroll px-4 mx-4"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "500px" }}
      >
        {paperStatus === "failed" ? (
          <>
            <table className="table table-striped mb-0 table-bordered">
              <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
                <tr>
                  <th scope="col" className="col-md-1">Paper ID</th>
                  <th scope="col" className="col-md-4">Authors</th>
                  <th scope="col" className="col-md-3">Title</th>
                  <th scope="col" className="col-md-0.5 writing-mode">Information</th>
                  <th scope="col" className="col-md-0.5 writing-mode">Paper</th>
                  <th scope="col" className="col-md-0.5 writing-mode">Review</th>
                  <th scope="col" className="col-md-0.5 writing-mode">Status</th>
                  <th scope="col" className="col-md-2">Time</th>
                </tr>
              </thead>
            </table>
            <h3 className="text-center my-2">No Submissions Found</h3>
          </>
        ) : (
          <table className="table table-striped mb-0 table-bordered">
            <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
              <tr>
                <th scope="col" className="col-md-1">Paper ID</th>
                <th scope="col" className="col-md-4">Authors</th>
                <th scope="col" className="col-md-3">Title</th>
                <th scope="col" className="col-md-0.5 writing-mode">Information</th>
                <th scope="col" className="col-md-0.5 writing-mode">Paper</th>
                <th scope="col" className="col-md-0.5 writing-mode">Review</th>
                <th scope="col" className="col-md-0.5 writing-mode">Update</th>
                <th scope="col" className="col-md-2">Time</th>
              </tr>
            </thead>
            {papers.map((data, index) => (
              <tbody key={index}>
                <tr className="schedule-item">
                  <td>{data.paperId}</td>
                  <td>
                    {userMapping[data.author]},
                    {data.authors.map((element, index) => (
                      <React.Fragment key={index}>
                        {userMapping[element]}
                        {index < data.authors.length - 1 ? ", " : ""}
                      </React.Fragment>
                    )) || "Loading..."}
                  </td>
                  <td>{data.title}</td>
                  <td>
                    <Link to={`/information-paper/${data._id}`}>
                      <img
                        width="20"
                        height="20"
                        src="https://img.icons8.com/ios-filled/50/info.png"
                        alt="info"
                      />
                    </Link>
                  </td>
                  <td>
                    <a href={`${data.file}`} className="table-url">
                      <img
                        width="24"
                        height="24"
                        src="https://img.icons8.com/plasticine/100/folder-invoices.png"
                        alt="folder-invoices"
                      />
                    </a>
                  </td>
                  <td>
                    <Link to={`/review-paper/${data._id}`}>
                      {papersReviewed.length > 0 ? <img
                        width="24"
                        height="24"
                        src="https://img.icons8.com/color/48/checkmark--v1.png"
                        alt="available-updates"
                      />
                      :
                      <img
                        width="24"
                        height="24"
                        src="https://img.icons8.com/color/48/hourglass.png"
                        alt="hourglass"
                      />}
                    </Link>
                  </td>
                  <td>Hello</td>
                  <td>{data.createdAt}</td>
                </tr>
              </tbody>
            ))}
          </table>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ViewPaper;
