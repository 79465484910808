import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import bg from "../welcome-bg.jpg";
import HeaderBackend from "../Components/HeaderBackend";
import { getToken } from "../utils/LocalStorage";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useFetchQuery, useMutateQuery } from "../utils/fetchData";
import base from "../utils/base";
import toast from "react-hot-toast";
import Spinner from "./Spinner";
import axios from "axios";
import { headers } from '../utils/http'

const SubmissionEdit = () => {
  const [submission, setSubmission] = useState([]);

  const [submissionsStatus, setSubmissionsStatus] = useState([]);

  const [userMapping, setUserMapping] = useState([]); // Mapping of userIds to names

  const { id } = useParams();

  const navigate = useNavigate()

  const url = `${base}/api/v1/submission/get/${id}`;

  const { data, isSuccess, error } = useFetchQuery(url, "submissions");

  useEffect(() => {
    if (isSuccess && data) {
      console.log(data);
      setSubmission(data.message);
      setSubmissionsStatus(data.status);
    }
  }, [isSuccess, data, submission, submissionsStatus]);

  if (error) {
    toast.error(error.message, { duration: 3000 });
  }

  // Organizer Details
  const fetchOrganizerDetails = (userId) => {
    const organizerHost = `${base}/api/v1/auth/get/id/${userId}`;

    fetch(organizerHost, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: getToken(),
      },
    }).then(async (res) => {
      const userData = await res.json();
      setUserMapping((prevMapping) => [
        ...prevMapping,
        {
          firstName: userData.message.firstName,
          lastName: userData.message.lastName,
          email: userData.message.email,
          country: userData.message.country,
          affiliation: userData.message.organizationName,
          id: userData.message._id,
        },
      ]);
    });
  };

  React.useEffect(() => {
    if (submissionsStatus === "success") {
      // Clear the previous user mapping
      setUserMapping([]);

      // Fetch details for all collaborating authors
      submission.authors.forEach(fetchOrganizerDetails);

      // Fetch details for the main author
      fetchOrganizerDetails(submission.author);
    }
  }, [submission, submissionsStatus]);


  const url1 = `${base}/api/v1/submission/update/${id}`;

  const myheaders = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    Authorization: getToken(),
  };

  const handleSuccess = (data) => {
    toast.success(data.message, { duration: 3000 });
    navigate(`/submission/${id}`);
  };

  const handleError = (submitError) => {
    toast.error(submitError.response.data.message, { duration: 3000 });
  };

  const {
    mutate,
    isPending,
    isError,
    error: submitError,
  } = useMutateQuery(url1, handleSuccess, handleError, myheaders);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    const titleInput = e.target.title;
    if (titleInput.value.trim() !== '') {
      formData.append("title", titleInput.value);
    }

    const abstractInput = e.target.abstract;
    if (abstractInput.value.trim() !== '') {
      formData.append("abstract", abstractInput.value);
    }

    const revisedFileInput = e.target.revisedFile;
    if (revisedFileInput.files.length > 0) {
      formData.append("revisedFile", revisedFileInput.files[0]);
    }

    const cameraReadyFileInput = e.target.cameraReadyFile;
    if (cameraReadyFileInput.files.length > 0) {
      formData.append("cameraReadyFile", cameraReadyFileInput.files[0]);
    }

    const paymentRecieptInput = e.target.paymentReciept;
    if (paymentRecieptInput.files.length > 0) {
      formData.append("paymentReciept", paymentRecieptInput.files[0]);
    }

    await mutate(formData);
  };

  const deleteAuthor = async (submissionId, authorId) => {
    try {
      const response = await axios.delete(`${base}/api/v1/submission/delete/${submissionId}/${authorId}`, { headers });

      const data = await response.data;
      if (data.status === 'success') {
        toast.success(data.message, { duration: 3000 })
        navigate(`/submission/${id}`);
      }
      else{
        toast.error(data.message, { duration: 3000 })
      }

    } catch (error) {
      toast.error(error.message, { duration: 3000 })
    }
  };



  const [emails, setEmails] = useState([]);

  const [emailsArray, setEmailsArray] = useState([]);

  const handleAddAuthors = (e) => {
    e.preventDefault();
    if (emails.includes(",")) {
      const emailArray = emails.split(",").map((email) => email.trim());
      setEmailsArray((prevEmails) => [...prevEmails, ...emailArray]);
    } else {
      setEmailsArray((prevEmails) => [...prevEmails, emails.trim()]);
    }
  };


  const url2 = `${base}/api/v1/submission/add/authors/${id}`;

  const handleAuthorSuccess = (data) => {
    toast.success(data.message, { duration: 3000 });
    navigate(`/submission/${id}`);
  };

  const handleAuthorError = (submitError) => {
    toast.error(submitError.response.data.message, { duration: 3000 });
  };

  const { mutate: authorMutate, isPending: authorPending, error: authorAddError } = useMutateQuery(
    url2,
    handleAuthorSuccess,
    handleAuthorError,
    headers
  );

  const handleAddEmail = () => {
    if (emailsArray.length > 0) {
      const formData = new FormData();
      emailsArray.forEach((email) => {
        formData.append("emails[]", email);
      });

      console.log(formData);

      authorMutate(formData);
    }
  };

  return (
    <div>
      <section
        id="welcome"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <HeaderBackend />
        <div className="container position-relative pt-5" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-6">
              <h2>Submission Id -: 139</h2>
              <p>
                Sed nam ut dolor qui repellendus iusto odit. Possimus inventore
                eveniet accusamus error amet eius aut accusantium et. Non odit
                consequatur repudiandae sequi ea odio molestiae. Enim possimus
                sunt inventore in est ut optio sequi unde.
              </p>
            </div>
            <div className="col-lg-3">
              <h3>Where</h3>
              <p>Downtown Conference Center, New York</p>
            </div>
            <div className="col-lg-3">
              <h3>When</h3>
              <p>
                Monday to Wednesday
                <br />
                10-12 December
              </p>
            </div>
          </div>
        </div>
      </section>
      <div
        className="information table-responsive table-scroll p-5 m-5 pb-0 mb-0"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "700px" }}
      >
        <div className="d-flex flex-row justify-content-center">
          <h2 className="text-center">Submission Details</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Title</th>
                <td scope="row">
                  <input
                    type="text"
                    name="title"
                    placeholder={submission.title}
                  />
                </td>
              </tr>
              <tr>
                <th scope="col">Paper</th>
                <td scope="row">
                  <a href={`${submission.file}`} className="table-url">
                    <img
                      width="24"
                      height="24"
                      src="https://img.icons8.com/plasticine/100/folder-invoices.png"
                      alt="folder-invoices"
                    />
                  </a>
                </td>
              </tr>
              <tr>
                <th scope="col">Author Keywords</th>
                <td scope="row">RPA degree job automation procedure robotic</td>
              </tr>
              <tr>
                <th scope="col">Abstract</th>
                <td scope="row">
                  <input
                    type="text"
                    name="abstract"
                    placeholder={submission.abstract}
                  />
                </td>
              </tr>
              <tr>
                <th scope="col" className="bg-table">
                  Submitted
                </th>
                <td scope="row">{submission.createdAt}</td>
              </tr>
              <tr>
                <th scope="col">Last Updated</th>
                <td scope="row">{submission.updatedAt}</td>
              </tr>
              <tr>
                <th scope="col">Decision</th>
                <td scope="row">{submission.decision}</td>
              </tr>
              {/* Revised paper will only be rendered if decision is update. */}
              <tr>
                <th scope="col">Revised Paper</th>
                <td scope="row">
                  <input
                    type="file"
                    name="revisedFile"
                  />
                </td>
              </tr>

              {/* Camera ready paper & payment reciept will only be rendered if decision is accepted. */}
              <tr>
                <th scope="col">Camera-Ready Paper</th>
                <td scope="row">
                  <input
                    type="file"
                    name="cameraReadyFile"
                  />
                </td>
              </tr>
              <tr>
                <th scope="col">Payment Reciept</th>
                <td scope="row">
                  <input
                    type="file"
                    name="paymentReciept"
                  />
                </td>
              </tr>
            </thead>
          </table>
          <button type="submit">{isPending ? <Spinner /> : 'Update'}</button>
        </form>
      </div>

      <h2 className="text-center">Authors</h2>
      <div
        className="table-responsive table-scroll px-5 mx-5 pb-5 mb-5"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "400px" }}
      >
        <table className="table table-striped mb-0">
          <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
            <tr>
              <th scope="col" className="col-md-2">
                First Name
              </th>
              <th scope="col" className="col-md-2">
                Last Name
              </th>
              <th scope="col" className="col-md-3">
                Email
              </th>
              <th scope="col" className="col-md-1">
                Country
              </th>
              <th scope="col" className="col-md-3">
                Affiliation
              </th>
              <th scope="col" className="col-md-1">
                Corresponding
              </th>
              <th scope="col" className="col-md-1">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {userMapping.map((element, index) => (
              <tr className="schedule-item" key={index}>
                <td>{element.firstName}</td>
                <td>{element.lastName}</td>
                <td>{element.email}</td>
                <td>{element.country} </td>
                <td>{element.affiliation}</td>
                <td>Yes</td>
                <td onClick={() => deleteAuthor(id, element.email)}>
                  <img
                    width="48"
                    height="48"
                    src="https://img.icons8.com/fluency/48/filled-trash.png"
                    alt="filled-trash"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="d-flex flex-row my-4">
          <div className="col-md-4 mb-4">
            <div className="form-outline">
              <input
                type="text"
                name="emails"
                placeholder="Enter email to add collaborator"
                className="form-control"
                value={emails}
                onChange={(e) => setEmails(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-4 mb-4 px-4">
            <div className="form-outline">
              <div className="btn btn-secondary" onClick={handleAddAuthors}>Add</div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="form-outline">
              <div className="btn btn-secondary" onClick={handleAddEmail}>Add Emails</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SubmissionEdit;
