import React, { useState, useEffect } from "react";
import bg from "../hero-bg.jpg";
import Header from "../home/Header";
import Footer from "./Footer";
import PulseLoader from "react-spinners/PulseLoader";
import { storeToken } from "../utils/LocalStorage";
import HeaderLogin from "../home/HeaderLogin";
import HeaderRegister from "../home/HeaderRegister";
import toast from "react-hot-toast";
import Spinner from "./Spinner";
import { useMutateQuery } from "../utils/fetchData";
import base from "../utils/base";

const Register = () => {
  const url = `${base}/api/v1/auth/register`;

  const handleSuccess = (data) => {
    toast.success(data.message, { duration: 3000 });
    setTimeout(() => {
      window.location.href = "/";
    }, 3000);
  };

  const handleError = (data) => {
    toast.error(data.response.data.message, { duration: 3000 });
  };

  const { mutate, isPending, isError, error } = useMutateQuery(
    url,
    handleSuccess,
    handleError
  );

  const handleRegister = async (e) => {
    e.preventDefault();

    const formData = {
      firstName: e.target.firstName.value,
      lastName: e.target.lastName.value,
      email: e.target.email.value,
      mobileNumber: e.target.mobileNumber.value,
      organizationName: e.target.organizationName.value,
      country: e.target.country.value,
      googleScholarId: e.target.googleScholarId.value,
      semanticScholarId: e.target.semanticScholarId.value,
      dblpId: e.target.dblpId.value,
      orcidId: e.target.orcidId.value,
      tc: e.target.tc.checked,
      password: e.target.password.value,
      confirm_password: e.target.confirm_password.value,
    };

    await mutate(formData);
  };


  return (
    <div>
      <div
        style={{ backgroundImage: `url(${bg})` }}
        id="hero1"
        className="pb-5"
      >
        <HeaderRegister />
        <section className="pt-5 mt-3">
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-12 col-md-8 col-lg-6 col-xl-6">
                <div
                  className="card shadow-2-strong"
                  style={{ borderRadius: "1rem" }}
                >
                  <form className="card-body p-5" onSubmit={handleRegister}>
                    <h3 className="mb-3 text-center">Register to ConfChair</h3>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label required">
                            First Name
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label required">
                            Last Name
                          </label>
                          <input
                            type="text"
                            name="lastName"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label required">
                            Email Address
                          </label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label">
                            Alternate Email Address
                          </label>
                          <input
                            type="email"
                            name="altEmail"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label required">
                            Mobile Number
                          </label>
                          <input
                            type="tel"
                            name="mobileNumber"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label">
                            Alternate Mobile Number
                          </label>
                          <input
                            type="tel"
                            name="altMobileNumber"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label required">
                            Organization Name
                          </label>
                          <input
                            type="text"
                            name="organizationName"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label required">City</label>
                          <input
                            type="text"
                            name="city"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label required">State</label>
                          <input
                            type="text"
                            name="state"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label required">Country</label>
                          <input
                            type="text"
                            name="country"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label required">Pincode</label>
                          <input
                            type="text"
                            name="pincode"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label">Facebook Id</label>
                          <input
                            type="text"
                            name="facebook"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label">Linkedin Id</label>
                          <input
                            type="text"
                            name="linkedin"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label">Twitter Id</label>
                          <input
                            type="text"
                            name="twitter"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label">
                            Google Scholar Id
                          </label>
                          <input
                            type="text"
                            name="googleScholarId"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label">
                            Semantic Scholar Id
                          </label>
                          <input
                            type="text"
                            name="semanticScholarId"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label">DBLP Id</label>
                          <input
                            type="text"
                            name="dblpId"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label">ORCID Id</label>
                          <input
                            type="text"
                            name="orcidId"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label required">
                            Password
                          </label>
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <label className="form-label required">
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            name="confirm_password"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="form-check mb-0">
                        <input
                          className="form-check-input me-2"
                          type="checkbox"
                          name="tc"
                        />
                        <label className="form-check-label form-label required">
                          I agree to Conf Chair Terms and Conditions.
                        </label>
                      </div>
                    </div>

                    <div className="text-center text-lg-start mt-4 pt-2">
                      <button
                        type="submit"
                        className="btn bg-custom-red text-white btn-lg btn-hover"
                        style={{
                          paddingLeft: "2.5rem",
                          paddingRight: "2.5rem",
                        }}
                      >
                        {isPending ? <Spinner /> : "Register"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
