import React from "react";
import Welcome from "../Components/Welcome";
import Footer from "../Components/Footer";
import AcceptedConferences from "./AcceptedConferences";
import AllConferencesOrganizer from "./AllConferencesOrganizer";

const OrganizerView = () => {

  return (
    <div>
      <Welcome />
      <section id="schedule" className="section-with-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-header text-center justify-content-center">
            <h2>Conference Requests</h2>
            <p>You can manage your conferences here</p>
          </div>

          <ul
            className="nav nav-tabs"
            role="tablist"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <li className="nav-item">
              <button
                className="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                style={{ backgroundColor: "#f82249" }}
              >
                Accepted Conferences
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                All Conferences
              </button>
            </li>
          </ul>

          <h3 className="sub-heading">
            Voluptatem nulla veniam soluta et corrupti consequatur neque eveniet
            officia. Eius necessitatibus voluptatem quis labore perspiciatis
            quia.
          </h3>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <AcceptedConferences />
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <AllConferencesOrganizer />
            </div>
            <div
              className="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              ...
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default OrganizerView;
