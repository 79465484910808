import React, { useEffect, useState } from "react";
import bg from "../welcome-bg.jpg";
import HeaderChair from "./HeaderChair";
import { useFetchQuery } from "../utils/fetchData";
import toast from "react-hot-toast";
import base from "../utils/base";

const WelcomeChair = ({id}) => {

  const [conference, setConference] = useState([]);


  const url = `${base}/api/v1/conference/${id}`

  const { data, isSuccess, error } = useFetchQuery(url, 'ConferenceByID')

  useEffect(() => {
    if (isSuccess && data) {
      setConference(data.message);
    }
  }, [isSuccess, data, conference]);

  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }


  return (
    <div>
      <section
        id="welcome"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <HeaderChair id={id} />
        <div
          className="container position-relative pt-5 text-center"
          data-aos="fade-up"
        >
          <h1>{isSuccess ? conference.acronym : 'Loading...'}</h1>
          <h3>{isSuccess ? conference.conferenceTitle : 'Loading...'}</h3>
        </div>
      </section>
    </div>
  );
};

export default WelcomeChair;
