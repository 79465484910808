import React, { useEffect, useState } from "react";
import WelcomeChair from "./WelcomeChair";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../Components/Footer";
import { useFetchQuery, useMutateQuery } from "../utils/fetchData";
import toast from "react-hot-toast";
import base from "../utils/base";
import Spinner from "../Components/Spinner";

const EditConference = () => {

  const [conference, setConference] = useState([]);

  const navigate = useNavigate()

  const { id } = useParams()

  const [user, setUser] = useState([]);

  const url = `${base}/api/v1/auth/me`;

  const { data, isSuccess, error } = useFetchQuery(url, "User");

  useEffect(() => {
    if (isSuccess && data) {
      setUser(data.data);
    }
  }, [isSuccess, data, user]);

  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }


  const url2 = `${base}/api/v1/conference/${id}`

  const { data: singleConf, isSuccess: confSucess, error: acceptedError } = useFetchQuery(url2, 'SingleConferences')

  useEffect(() => {
    if (confSucess && singleConf) {
      setConference(singleConf.message);
    }
  }, [confSucess, singleConf, conference]);

  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }


  const url3 = `${base}/api/v1/conference/update/${id}`

  const handleSuccess = (data) => {
    toast.success(data.message, { duration: 3000 });
    navigate(`/welcome`);
  };

  const handleError = (submitError) => {
    toast.error(submitError.response.data.message, { duration: 3000 });
  };

  const { mutate, isPending, isError, error: updationError } = useMutateQuery(url3, handleSuccess, handleError)

  const handleUpdate = async (e) => {
    e.preventDefault();

    const formData = {
      modeOfConference: e.target.modeOfConference.value,
      venue: e.target.venue.value,
      city: e.target.city.value,
      country: e.target.country.value,
      startDate: e.target.startDate.value,
      endDate: e.target.endDate.value,
      primaryArea: e.target.primaryArea.value,
      secondaryArea: e.target.secondaryArea.value,
    };

    await mutate(formData);
  };

  return (
    <div>
      <WelcomeChair />
      <div className="section-header text-center justify-content-center pt-4 mt-4">
        <h2>Edit Conference</h2>
        <p>Here you can edit details about your conference</p>
      </div>
      <h2 className="text-center pt-4 mt-4">Organizer</h2>
      <div
        className="table-responsive table-scroll px-5 mx-5"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "150px" }}
      >
        <table className="table table-striped mb-0">
          <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
            <tr>
              <th scope="col" className="col-md-2">
                First Name
              </th>
              <th scope="col" className="col-md-2">
                Last Name
              </th>
              <th scope="col" className="col-md-3">
                Email
              </th>
              <th scope="col" className="col-md-1">
                Affiliation
              </th>
              <th scope="col" className="col-md-3">
                Contact
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="schedule-item">
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>{user.email}</td>
              <td>{user.organizationName}</td>
              <td>{user.mobileNumber}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        className="information table-responsive table-scroll p-5 m-5 pb-0 mb-0"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "900px" }}
      >
        <div className="d-flex flex-row justify-content-between">
          <h2 className="text-center">Conference Name</h2>
        </div>
        <form onSubmit={handleUpdate}>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <td scope="row">{conference.type}</td>
              </tr>
              <tr>
                <th scope="col">Conference Title</th>
                <td scope="row">{conference.conferenceTitle}</td>
              </tr>
              <tr>
                <th scope="col">Acronym</th>
                <td scope="row">{conference.acronym}</td>
              </tr>
              <tr>
                <th scope="col">Web Page</th>
                <td scope="row">{conference.webPage}</td>
              </tr>
              <tr>
                <th scope="col">Mode Of Conference</th>
                <td scope="row">
                  <input
                    type="text"
                    name="modeOfConference"
                    placeholder={conference.modeOfConference}
                  />
                </td>
              </tr>
              <tr>
                <th scope="col">Venue</th>
                <td scope="row">
                  {" "}
                  <input
                    type="text"
                    name="venue"
                    placeholder={conference.venue}
                  />
                </td>
              </tr>
              <tr>
                <th scope="col">City</th>
                <td scope="row">
                  <input
                    type="text"
                    name="city"
                    placeholder={conference.city}
                  />
                </td>
              </tr>
              <tr>
                <th scope="col">Country/Region</th>
                <td scope="row">
                  <input
                    type="text"
                    name="country"
                    placeholder={conference.country}
                  />{" "}
                </td>
              </tr>
              <tr>
                <th scope="col">Estimated number of submissions</th>
                <td scope="row"> </td>
              </tr>
              <tr>
                <th scope="col">Start Date</th>
                <td scope="row">
                  <input
                    type="text"
                    name="startDate"
                    placeholder={conference.startDate}
                  />{" "}
                </td>
              </tr>
              <tr>
                <th scope="col">End Date</th>
                <td scope="row">
                  <input
                    type="text"
                    name="endDate"
                    placeholder={conference.endDate}
                  />{" "}
                </td>
              </tr>
              <tr>
                <th scope="col">Primary Area</th>
                <td scope="row">
                  <input
                    type="text"
                    name="primaryArea"
                    placeholder={conference.primaryArea}
                  />{" "}
                </td>
              </tr>
              <tr>
                <th scope="col">Secondary Area</th>
                <td scope="row">
                  {" "}
                  <input
                    type="text"
                    name="secondaryArea"
                    placeholder={conference.secondaryArea}
                  />
                </td>
              </tr>
              <tr>
                <th scope="col">Area Notes</th>
                <td scope="row"> </td>
              </tr>
              <tr>
                <th scope="col">Any other Information</th>
                <td scope="row"> </td>
              </tr>
            </thead>
          </table>
        <button className="btn btn-custom1 btn-block" type="submit">{isPending ? <Spinner /> : 'Update'}</button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default EditConference;
