import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import base from "../utils/base"
import { useNavigate } from 'react-router-dom';
import { useFetchQuery } from '../utils/fetchData';
import { toast } from 'react-hot-toast';

const Verify = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    const url = `${base}/api/v1/auth/verify/${token}`

    const { data, isSuccess, error } = useFetchQuery(url, 'verfiy-mail')

    useEffect(() => {
        if (isSuccess && data) {
            toast.success(data.message, { duration: 3000 });
            navigate('/welcome')
        }
    }, [isSuccess]);

    if (error) {
        toast.error(error.response.data.message, { duration: 3000 });
    }

    return (
        <>
            <main className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mx-auto h-12 w-auto text-green-500">
                            <polyline points="20 6 9 17 4 12" />
                        </svg>
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Email Verified</h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            You will be redirected to the dashboard in 5 seconds.
                        </p>
                    </div>
                </div>
            </main>
            )
        </>
    )
}

export default Verify