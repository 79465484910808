import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import WelcomeChair from "./WelcomeChair";
import Information from "./Information";
import Footer from "../Components/Footer";
import { getToken } from "../utils/LocalStorage";
import base from "../utils/base";

const Assignment = () => {
  const [modalShow, setModalShow] = useState(false);
  const openModal = () => setModalShow(true);
  const closeModal = () => setModalShow(false);

  const { id } = useParams();
  const [paper, setPaper] = useState([]);
  const [paperStatus, setPaperStatus] = useState();
  const [reviewer, setReviewer] = useState([]);
  const [reviewerStatus, setReviewerStatus] = useState();

  const host = `${base}/api/v1/submission/get/${id}`;
  useEffect(() => {
    fetch(host, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then(async (res) => {
      const data = await res.json();
      setPaper(data.message);
      setPaperStatus(data.status);
    });
  }, [host]);

  const host2 = `${base}/api/v1/conference/view/reviewers/${paper.conference}`;
  useEffect(() => {
    fetch(host2, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then(async (res) => {
      const data = await res.json();
      setReviewer(data.message);
      setReviewerStatus(data.status);
    });
  }, [paper, host2]);


  const addReviewers = async (e) => {
    e.preventDefault();

    // Collect checked reviewer emails
    const selectedReviewers = Array.from(document.querySelectorAll('input[name="reviewer"]:checked'))
      .map((checkbox) => checkbox.value);

    console.log('Selected Reviewers:', selectedReviewers);

    const host = `${base}/api/v1/submission/update/reviewers/${id}`;
    try {
      const response = await fetch(host, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
        body: JSON.stringify({ reviewerEmails: selectedReviewers, operation: 'add' }),
      });

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error(error);
    }
};


  return (
    <>
      <WelcomeChair id={id} />
      <div className="d-flex align-items-center justify-content-center">
        <div className="col-6 table-responsive table-scroll px-4 mx-4 py-4 my-4" data-mdb-perfect-scrollbar="true" style={{}}>
          <h2 className="text-center">Reviewers</h2>
          <form onSubmit={addReviewers}>
            <table className="table table-striped mb-0 table-bordered">
              <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
                <tr>
                  <th scope="col" className="col-md-1">Select</th>
                  <th scope="col" className="col-md-3">Name</th>
                </tr>
              </thead>
              <tbody>
                {reviewerStatus === "success" ? reviewer.map((user, index) => {
                  const isReviewerAlreadyAdded = paper.reviewers.some(
                    (reviewer) => reviewer.user === user._id
                  );

                  return (
                    <tr className="schedule-item" key={index}>
                      <td>
                        <input
                          type="checkbox"
                          value={user.email} // Ensure user._id is used
                          className="form-input"
                          name="reviewer"
                          defaultChecked={isReviewerAlreadyAdded}
                        />
                      </td>
                      <td>{user.firstName || "Loading..."}</td>
                    </tr>
                  );
                }) : "No Reviewer Found"}
              </tbody>
            </table>
            <div className="text-center mt-2">
              <button type="submit" className="btn btn-block btn-custom">Update</button>
            </div>
          </form>
        </div>
      </div>
      <div className="information table-responsive table-scroll p-5 m-5 pb-0 mb-0" data-mdb-perfect-scrollbar="true" style={{ position: "relative", height: "700px" }}>
        <h2 className="text-center">Submission Details</h2>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Title</th>
              <td scope="row">
                {paperStatus === "success" ? paper.title : "No Paper Found"}
              </td>
            </tr>
            <tr>
              <th scope="col">Paper</th>
              <td scope="row">
                <a href="#" className="table-url">
                  <img
                    width="24"
                    height="24"
                    src="https://img.icons8.com/plasticine/100/folder-invoices.png"
                    alt="folder-invoices"
                  />
                </a>
              </td>
            </tr>
            <tr>
              <th scope="col">Author Keywords</th>
              <td scope="row">RPA degree job automation procedure robotic</td>
            </tr>
            <tr>
              <th scope="col">Abstract</th>
              <td scope="row">
                {paperStatus === "success" ? paper.abstract : "No Paper Found"}
              </td>
            </tr>
            <tr>
              <th scope="col" className="bg-table">Submitted</th>
              <td scope="row">
                {paperStatus === "success" ? paper.createdAt : "No Paper Found"}
              </td>
            </tr>
            <tr>
              <th scope="col">Last Updated</th>
              <td scope="row">
                {paperStatus === "success" ? paper.updatedAt : "No Paper Found"}
              </td>
            </tr>
            <tr>
              <th scope="col">Decision</th>
              <td scope="row"></td>
            </tr>
          </thead>
        </table>
      </div>
      <Footer />
    </>
  );
};

export default Assignment;