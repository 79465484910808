import React, { useEffect, useState } from "react";
import WelcomeChair from "./WelcomeChair";
import bg from "../welcome-bg.jpg";
import HeaderChair from "./HeaderChair";
import { Link, useParams } from "react-router-dom";
import Footer from "../Components/Footer";
import { useFetchQuery } from "../utils/fetchData";
import toast from "react-hot-toast";
import base from "../utils/base";

const InformationReviewer = () => {

  const { id } = useParams();
  const [allPending, setAllPending] = useState([]);
  const [allPendingStatus, setAllPendingStatus] = useState('');
  const [allReviewed, setAllReviewed] = useState([]);
  const [allReviewedStatus, setAllReviewedStatus] = useState('');

  const url1 = `${base}/api/v1/submission/all/pending/${id}`;
  const { data, isSuccess, error } = useFetchQuery(url1, 'ViewAllPending');

  useEffect(() => {
    if (isSuccess && data) {
      console.log(data);
      setAllPending(data.message);
      setAllPendingStatus(data.status);
    }
  }, [isSuccess, data]);

  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }

  const url2 = `${base}/api/v1/submission/all/reviewed/${id}`;
  const { data: reviewedData, isSuccess: isReviewed, error: reviewedError } = useFetchQuery(url2, 'ViewAllReviewed');

  useEffect(() => {
    if (isReviewed && reviewedData) {
      console.log(reviewedData);
      setAllReviewed(reviewedData.message || []); // Ensure it's an array or set it to an empty array
      setAllReviewedStatus(reviewedData.status);
    }
  }, [isReviewed, reviewedData]);

  if (reviewedError) {
    toast.error(reviewedError.response.data.message, { duration: 3000 });
  }

  return (
    <div>
      <section
        id="welcome"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <HeaderChair />
        <div className="container position-relative pt-5" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-6">
              <h2>Assigned Papers</h2>
              <p>
                Sed nam ut dolor qui repellendus iusto odit. Possimus inventore
                eveniet accusamus error amet eius aut accusantium et. Non odit
                consequatur repudiandae sequi ea odio molestiae. Enim possimus
                sunt inventore in est ut optio sequi unde.
              </p>
            </div>
            <div className="col-lg-3">
              <h3>Where</h3>
              <p>Downtown Conference Center, New York</p>
            </div>
            <div className="col-lg-3">
              <h3>When</h3>
              <p>
                Monday to Wednesday
                <br />
                10-12 December
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="section-header text-center justify-content-center pt-4 mt-4">
        <h2>Pending Papers</h2>
        <p>Here you can see the pending papers</p>
      </div>
      <div
        className="table-responsive table-scroll px-5 mx-5 pb-5 mb-5"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "200px" }}
      >
        <table className="table table-striped table-bordered mb-0">
          <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
            <tr>
              <th scope="col" className="col-md-2">
                Paper ID
              </th>
              <th scope="col" className="col-md-3">
                Title
              </th>
              <th scope="col" className="col-md-1">
                Information
              </th>
            </tr>
          </thead>
          <tbody>
            {allPendingStatus === 'success' && allPending.length > 0 ? (
              allPending.map((pending, pendingIndex) => (
                <tr key={pendingIndex} className="schedule-item">
                  <td>{pending.paperId}</td>
                  <td>{pending.title}</td>
                  <td>
                    <Link to={`/information/${pending._id}`}>
                      <img
                        width="20"
                        height="20"
                        src="https://img.icons8.com/ios-filled/50/info.png"
                        alt="info"
                      />
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">No Pending paper Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="section-header text-center justify-content-center pt-4 mt-4">
        <h2>Reviewed Papers</h2>
        <p>Here you can see the reviewed papers</p>
      </div>
      <div
        className="table-responsive table-scroll px-5 mx-5 pb-5 mb-5"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "400px" }}
      >
        <table className="table table-striped table-bordered mb-0">
          <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
            <tr>
              <th scope="col" className="col-md-2">
                Paper ID
              </th>
              <th scope="col" className="col-md-3">
                Title
              </th>
              <th scope="col" className="col-md-3">
                Ratings
              </th>
              <th scope="col" className="col-md-3">
                Comments
              </th>
              <th scope="col" className="col-md-1">
                Information
              </th>
            </tr>
          </thead>
          <tbody>
            {allReviewedStatus === 'success' && allReviewed.length > 0 ? (
              allReviewed.map((reviewed, reviewedIndex) => (
                <tr key={reviewedIndex} className="schedule-item">
                  <td>{reviewed.paperId}</td>
                  <td>{reviewed.title}</td>
                  {reviewed.reviewers.map((data, index) => (
                    <React.Fragment key={`${reviewedIndex}-${index}`}>
                      <td>{data.rating}</td>
                      <td>{data.comment}</td>
                      <td>
                        <Link to={`/information/${reviewed._id}`}>
                          <img
                            width="20"
                            height="20"
                            src="https://img.icons8.com/ios-filled/50/info.png"
                            alt="info"
                          />
                        </Link>
                      </td>
                    </React.Fragment>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No Reviewed paper Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Footer />
    </div>
  );
};

export default InformationReviewer;
