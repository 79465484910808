import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import bg from "../welcome-bg.jpg";
import { Link, useParams } from "react-router-dom";
import HeaderBackend from "./HeaderBackend";
import { getToken } from "../utils/LocalStorage";
import base from "../utils/base";
import { useFetchQuery } from "../utils/fetchData";
import toast from "react-hot-toast";
import { fetchData } from "../utils/http";

const Submission = () => {
  const [submissions, setSubmissions] = useState([]);

  const [submissionsStatus, setSubmissionsStatus] = useState([]);

  const [userMapping, setUserMapping] = useState({}); // Mapping of userIds to names


  const { id } = useParams();

  const url = `${base}/api/v1/submission/user/${id}`

  const { data, isSuccess, error } = useFetchQuery(url, 'SubmissionUser')

  useEffect(() => {
    if (isSuccess && data) {
      // console.log(submissions);
      setSubmissions(data.message);
      setSubmissionsStatus(data.status);
    }
  }, [isSuccess, data, submissions, submissionsStatus]);

  if (error) {
    console.log(error);
    toast.error(error.response.data.message, { duration: 3000 });
  }
  useEffect(() => {
    if (error) {
      setSubmissionsStatus(error.response.data.status);
    }
  }, [])





  const [isSubmissionAccepted, setIsSubmissionAccepted] = useState(false)

  const url3 = `${base}/api/v1/conference/${id}`

  useEffect(() => {
    const getSubmissionData = async () => {
      try {
        const submissionByID = await fetchData(url3);
        setIsSubmissionAccepted(submissionByID.message.acceptSubmissions)
      } catch (error) {
        console.error("Error fetching submission data:", error);
      }
    };

    getSubmissionData();
  }, [isSubmissionAccepted]);

  // console.log(isSubmissionAccepted);





  // Organizer Details
  const fetchOrganizerDetails = (userId) => {
    const organizerHost = `${base}/api/v1/auth/get/id/${userId}`;

    fetch(organizerHost, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: getToken(),
      },
    }).then(async (res) => {
      const userData = await res.json();
      // console.log(userData);
      setUserMapping((prevMapping) => ({
        ...prevMapping,
        [userId]: userData.message.firstName, // Assuming the user's name is in 'name' field of the response
      }));
    });
  };

  React.useEffect(() => {
    if (submissionsStatus === "success") {
      submissions.forEach((submission) => {
        // console.log(submission.colabAuthors);
        for (let i = 0; i < submission.authors.length; i++) {
          const element = submission.authors[i];
          fetchOrganizerDetails(element);
        }
        fetchOrganizerDetails(submission.author); // Assuming you have 'authorId' in your submission data
      });
    }
  }, [submissions, submissionsStatus]);


  const [conference, setConference] = useState([]);

  const url2 = `${base}/api/v1/conference/accepted/${id}`

  const { data: conferenceData, isSuccess: conferenceSuccess, error: conferenceError } = useFetchQuery(url2, 'AcceptedConference')

  useEffect(() => {
    if (conferenceSuccess && conferenceData) {
      setConference(conferenceData.message);
    }
  }, [conferenceSuccess, conferenceData, conference]);

  if (conferenceError) {
    toast.error(conferenceError.response.data.message, { duration: 3000 });
  }


  const notAccepted = () => {
    toast.error("Currently Submission not Accepted. Please try to contact to Organizer", { duration: 3000 })
  };

  return (
    <div>
      <section
        id="welcome"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <HeaderBackend />
        <div
          className="container position-relative pt-5 text-center"
          data-aos="fade-up"
        >
          <h1>Submissions</h1>
          <h3>Author Console</h3>
        </div>
      </section>
      <section id="schedule" className="vh-100">
        <div className="container" data-aos="fade-up">
          {isSubmissionAccepted === false  ? (
            <button
              className="btn btn-block justify-content-center text-center px-2 mx-3"
              style={{ backgroundColor: "#0e1b4d", color: "#fff" }}
              onClick={notAccepted}
            >
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/ios-filled/50/FFFFFF/plus.png"
                alt="plus"
                className="m-2"
              />
              Create new submission
            </button>
          ) : (
            <Link to={`/create/${id}`}>
              <button
                className="btn btn-block justify-content-center text-center px-2 mx-3"
                style={{ backgroundColor: "#0e1b4d", color: "#fff" }}
              >
                <img
                  width="24"
                  height="24"
                  src="https://img.icons8.com/ios-filled/50/FFFFFF/plus.png"
                  alt="plus"
                  className="m-2"
                />
                Create new submission
              </button>
            </Link>
          )}
          <div
            className="table-responsive table-scroll px-2 mx-2 pt-2 mt-4"
            data-mdb-perfect-scrollbar="true"
          >
            {submissionsStatus === "failed" ? (
              <>
                <table className="table table-striped mb-0 table-bordered">
                  <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
                    <tr>
                      <th scope="col" className="col-md-1">
                        Paper ID
                      </th>
                      <th scope="col" className="col-md-4">
                        Authors
                      </th>
                      <th scope="col" className="col-md-3">
                        Title
                      </th>
                      <th scope="col" className="col-md-0.5 writing-mode">
                        Decision
                      </th>
                      <th scope="col" className="col-md-0.5 writing-mode">
                        Information
                      </th>
                      <th scope="col" className="col-md-0.5 writing-mode">
                        Paper
                      </th>
                      <th scope="col" className="col-md-2">
                        Time
                      </th>
                    </tr>
                  </thead>
                </table>
                <h3 className="text-center my-2">No Submissions Found</h3>
              </>
            ) : (
              <table className="table table-striped mb-0 table-bordered">
                <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
                  <tr>
                    <th scope="col" className="col-md-1">
                      Paper ID
                    </th>
                    <th scope="col" className="col-md-3">
                      Authors
                    </th>
                    <th scope="col" className="col-md-3">
                      Title
                    </th>
                    <th scope="col" className="col-md-1">
                      Decision
                    </th>
                    {/* <th scope="col" className="col-md-1">
                      Revised Paper
                    </th>
                    <th scope="col" className="col-md-1">
                      Camera Ready Paper
                    </th> */}
                    <th scope="col" className="col-md-0.5 writing-mode">
                      Information
                    </th>
                    <th scope="col" className="col-md-0.5 writing-mode">
                      Paper
                    </th>
                    {/* <th scope="col" className="col-md-2">
                      Registration
                    </th> */}
                  </tr>
                </thead>
                {submissions.map((data, index) => {
                  return (
                    <tbody key={index}>
                      <tr className="schedule-item">
                        <td>{data.paperId}</td>
                        <td>
                          {userMapping[data.author]},
                          {data.authors.map((element, index) => (
                            <React.Fragment key={index}>
                              {userMapping[element]}
                              {index < data.authors.length - 1 ? ", " : ""}
                            </React.Fragment>
                          )) || "Loading..."}
                        </td>
                        <td>{data.title}</td>
                        <td>{data.decision}</td>
                        <td>
                          <Link to={`/submission-view/${data._id}`}>
                            <img
                              width="20"
                              height="20"
                              src="https://img.icons8.com/ios-filled/50/info.png"
                              alt="info"
                            />
                          </Link>
                        </td>
                        <td>
                          <a href={`${data.file}`} className="table-url">
                            <img
                              width="24"
                              height="24"
                              src="https://img.icons8.com/plasticine/100/folder-invoices.png"
                              alt="folder-invoices"
                            />
                          </a>
                        </td>
                        {/*
                        <td>
                          <button className="btn btn-custom">Pay Now</button>
                        </td> */}
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Submission;
