import React, { useState, useEffect } from "react";
import bg from "../hero-bg.jpg";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import { useMutateQuery } from "../utils/fetchData";
import base from "../utils/base";
import toast from "react-hot-toast";

const ForgetPassword = () => {

  const navigate = useNavigate();

  const url = `${base}/api/v1/auth/password/reset`

  const handleSuccess = (data) => {
    toast.success(data.message, { duration: 3000 });
    navigate('/')
  };

  const handleError = (error) => {
    toast.error(error.response.data.message, { duration: 3000 });
  };

  const { mutate, isPending, isError, error } = useMutateQuery(url, handleSuccess, handleError)


  const handleForgotPassword = async (e) => {
    e.preventDefault();

    const formData = {
      email: e.target.email.value
    };

    await mutate(formData);
  };


  return (
    <div className="vh-100" style={{ backgroundImage: `url(${bg})` }}>
      <section className="login padding-top-form-less" id="hero1">
        <div className="container h-25">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: "1rem" }}
              >
                <form onSubmit={handleForgotPassword} className="card-body p-5">
                  <h3 className="mb-3 text-center">Forget Password</h3>
                  <div className="form-outline mb-4">
                    <label className="form-label" for="typeEmailX-2">
                      Enter your registered email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="typeEmailX-2"
                      className="form-control form-control-lg"
                    />
                  </div>

                  <div className="text-center text-lg-start mt-2">
                    <div>
                      <button
                        type="sumbit"
                        className="btn bg-custom-red text-white btn-lg btn-hover"
                        style={{
                          paddingLeft: "2.5rem",
                          paddingRight: "2.5rem",
                        }}
                      >
                        {isPending ? <Spinner/> : 'Forget Password'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForgetPassword;
