import React, { useRef } from "react";
import { Link } from "react-router-dom";

const HeaderLogin = ({
  mainRef,
  aboutRef,
  servicesRef,
  featuresRef,
  pricingRef,
  contactRef,
}) => {
  const ref = useRef(null);

  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
    const div = document.querySelector("#navbar");
    div.classList.remove("navbar-mobile");
    div.classList.add("navbar");
  };

  function mobileNavView() {
    const div = document.querySelector("#navbar");
    div.classList.remove("navbar");
    div.classList.toggle("navbar-mobile");
  }
  return (
    <div>
      <header id="header" className="d-flex align-items-center ">
        <div className="container-fluid container-xxl d-flex align-items-center">
          <div id="logo" className="me-auto mt-3 pt-3">
            {/* <h1>
              <Link to="/">
                CONF<span>CHAIR</span>
              </Link>
            </h1> */}
            <Link to="/" className="scrollto">
              <img src="assests/Images/logo1.png" alt="CONF CHAIR" />
            </Link>
          </div>
          <ul>
            <Link className="nav-link link-button scrollto" to="/register">
              <button className="btn btn-log scrollto nav-btn nav-btn-mobile">
                Register
              </button>
            </Link>
          </ul>
        </div>
      </header>
    </div>
  );
};

export default HeaderLogin;
