import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFetchQuery } from "../utils/fetchData";
import base from "../utils/base";
import toast from "react-hot-toast";
import axios from "axios";

const MyConfrences = () => {

  const [user, setUser] = useState([]);
  const [allConferences, setAllConferences] = useState([]);
  const [authorData, setAuthorData] = useState([]);
  const [reviewerData, setReviewerData] = useState([]);
  const [chairData, setChairData] = useState([]);


  const url = `${base}/api/v1/auth/me`

  const { data, isSuccess, error } = useFetchQuery(url, 'User')

  useEffect(() => {
    if (isSuccess && data) {
      setUser(data.data);
      if (data.data.authorData) {
        setAuthorData(data.data.authorData)
      }
      if (data.data.reviewerData) {
        setReviewerData(data.data.reviewerData)
      }
      if (data.data.chairData) {
        setChairData(data.data.chairData)
      }
    }
  }, [isSuccess, data, user, authorData, reviewerData, chairData]);

  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }


  const url2 = `${base}/api/v1/conference/accepted/all`

  const { data: acceptedConf, isSuccess: acceptedSucess, error: acceptedError } = useFetchQuery(url2, 'AllConferences')

  useEffect(() => {
    if (acceptedSucess && acceptedConf) {
      setAllConferences(acceptedConf.message);
    }
  }, [isSuccess, acceptedConf, allConferences]);

  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }


  const [conferenceTitles, setConferenceTitles] = useState({});

  const getConferenceTitle = async (conferenceId) => {
    try {
      const response = await axios.get(`${base}/api/v1/conference/${conferenceId}`);
      const singleConf = response.data;

      if (singleConf) {
        setConferenceTitles(prevTitles => ({
          ...prevTitles,
          [conferenceId]: singleConf.message.conferenceTitle
        }));
      }
    } catch (error) {
      console.error('Error fetching conference title:', error);
    }
  };

  const [submissionTitle, setSubmissionTitles] = useState({});

  const getSubmissionTitle = async (submissionId) => {
    try {
      const response = await axios.get(`${base}/api/v1/submission/get/${submissionId}`);
      const singleSub = response.data;

      if (singleSub) {
        setSubmissionTitles(prevTitles => ({
          ...prevTitles,
          [submissionId]: singleSub.message.title
        }));
      }
    } catch (error) {
      console.error('Error fetching conference title:', error);
    }
  };

  useEffect(() => {
    // Iterate through chair data and fetch conference titles
    chairData.forEach(chair => {
      getConferenceTitle(chair.conference);
    });
    reviewerData.forEach(reviewer => {
      getConferenceTitle(reviewer.conference);
    });
    authorData.forEach(author => {
      getSubmissionTitle(author.submission);
    });
  }, [chairData, reviewerData, authorData]);

  return (
    <div>
      <div className="table-responsive table-scroll" data-mdb-perfect-scrollbar="true" style={{ position: "relative", height: "700px" }}>
        {error ? (
          <table className="table table-striped mb-0">
            <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
              <tr>
                <th scope="col" className="col-md-4">
                  Conference Name
                </th>
                <th scope="col" className="col-md-4">
                  Role
                </th>
                <th scope="col" className="col-md-4">
                  External URL
                </th>
              </tr>
            </thead>
            <h4 className="text-center my-2">No Active Role Found</h4>
          </table>
        ) : (
          <table className="table table-striped mb-0">
            <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
              <tr>
                <th scope="col" className="col-md-4">
                  Conference Name
                </th>
                <th scope="col" className="col-md-1">
                  Role
                </th>
                <th scope="col" className="col-md-4">
                  External URL
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Iterate through author data */}
              {authorData.map((author) => (
                <tr key={author._id} >
                  {/* <td>{author.submission}</td> */}
                  <td>{submissionTitle[author.submission] || 'Not found'}</td>
                  <td>Author</td>
                  <td>
                    <Link to={`/submission-view/${author.submission}`}>Go to Submission</Link>
                  </td>
                </tr>
              ))}

              {/* Iterate through reviewer data */}
              {reviewerData.map((reviewer) => (
                <tr key={`${reviewer._id}`}>
                  <td>{conferenceTitles[reviewer.conference] || 'Not found'}</td>
                  <td>Reviewer</td>
                  <td>
                    <Link to={`/view-paper/${reviewer.conference}`}>Go to Reviewer</Link>
                  </td>
                </tr>
              ))
              }

              {/* Iterate through chair data */}
              {chairData.map((chair) => (
                <tr key={chair._id}>
                  <td>{conferenceTitles[chair.conference] || 'Not found'}</td>
                  <td>Chair</td>
                  <td>
                    <Link to={`/manage-conference/${chair.conference}`}>Go to Chair</Link>
                  </td>
                </tr>
              ))}

              {/* Add additional conditions or data fields as needed */}

              {/* Display message if no active roles */}
              {authorData.length === 0 && reviewerData.length === 0 && chairData.length === 0 && (
                <tr>
                  <td colSpan="3" className="text-center">No Active Role Found</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div >
  );
};

export default MyConfrences;
