import React, { useEffect, useState } from "react";
import WelcomeChair from "./WelcomeChair";
import Footer from "../Components/Footer";
import { getToken } from "../utils/LocalStorage";
import { useParams } from "react-router-dom";
import base from "../utils/base";
import { useFetchQuery } from "../utils/fetchData";
import toast from "react-hot-toast";

const Decision = () => {

  const [papers, setPapers] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [papersStatus, setPapersStatus] = useState([]);
  const [reviewersStatus, setReviewersStatus] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const { id } = useParams();

  const url = `${base}/api/v1/submission/getby/conference/${id}`

  const { data, isSuccess, error } = useFetchQuery(url, 'SubmissionUsingConference')

  useEffect(() => {
    if (isSuccess && data) {
      setPapers(data.message);
      setPapersStatus(data.status);
    }
  }, [isSuccess, data, papers, papersStatus]);

  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }


  const url2 = `${base}/api/v1/conference/view/reviewers/${id}`

  const { data: reviewerData, isSuccess: reviewerSuccess, error: reviewerError } = useFetchQuery(url2, 'ConferencveReviewer')

  useEffect(() => {
    if (reviewerSuccess && reviewerData) {
      setReviewers(reviewerData.message);
      setReviewersStatus(reviewerData.status);
    }
  }, [reviewerSuccess, reviewerData, papers, papersStatus]);

  if (reviewerError) {
    toast.error(reviewerError.response.data.message, { duration: 3000 });
  }



  const handleAccept = async (id) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${base}/api/v1/submission/update/submission/${id}/accept`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': `${getToken()}`
        },
      });
      const data = await response.json();
      if (data.status === 'failed') {
        toast.error(data.message, {duration:3000});
      } else {
        toast.success(data.message, {duration:3000});
        console.log(data);
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred', {duration:3000});
    } finally {
      setIsLoading(false);
    }
  };

  const handleReject = async (id) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${base}/api/v1/submission/update/submission/${id}/reject`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': `${getToken()}`
        },
      });
      const data = await response.json();
      if (data.status === 'failed') {
        toast.error(data.message, {duration:3000});
      } else {
        toast.success(data.message, {duration:3000});
        console.log(data);
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred', {duration:3000});
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div>
      <WelcomeChair id={id}/>
      <div className="section-header text-center justify-content-center pt-4 mt-4">
        <h2>Decision</h2>
        <p>Here you can give the decision</p>
      </div>

      <div className="d-flex flex-row justify-content-center text-center flex-wrap">
        {papers.map((paper, index) => (
          <div key={index} className="col-4 paperwise text-center mt-4 pt-4">
            <div className="text-center">
              <div className="paper col-4 col-md-4 col-lg-4 mx-1 mt-3 bg-border-custom-title d-inline-block justify-content-center">
                <span>
                  <h3 className="paper-title pt-2">{paper.title}</h3>
                  <div className="d-flex flex-row justify-content-center flex-wrap">
                    <p className="paper-desc m-0 px-1 text-uppercase">
                      Ratings-:{" "}
                    </p>
                    {paper.reviewers.map((reviewer, reviewerIndex) => (
                      <p key={reviewerIndex} className="paper-desc m-0 px-1">{reviewer.rating}</p>
                    ))}
                  </div>
                </span>
              </div>
            </div>
            <p className="box d-flex flex-row justify-content-center flex-wrap">
              <label className="paper col-4 col-md-4 col-lg-3 mx-1 mt-3 bg-border-custom">
                <input type="radio" name="Sanjay" value="1" />
                <span onClick={() => handleAccept(paper._id)} style={{ cursor: 'pointer' }}>
                  <p className="paper-title pt-2">Accept</p>
                </span>
              </label>
              <label className="paper col-4 col-md-4 col-lg-3 mx-1 mt-3 bg-border-custom">
                <input type="radio" name="Sanjay" value="1" />
                <span onClick={() => handleReject(paper._id)} style={{ cursor: 'pointer' }}>
                  <p className="paper-title pt-2">Reject</p>
                </span>
              </label>
              <label className="paper col-4 col-md-4 col-lg-3 mx-1 mt-3 bg-border-custom">
                <input type="radio" name="Sanjay" value="1" />
                <span style={{ cursor: 'pointer' }}>
                  <p className="paper-title pt-2">Update</p>
                </span>
              </label>
            </p>
            <hr className="col-11 mx-auto dark-hr mt-4" />
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
};

export default Decision;
