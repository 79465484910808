import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import WelcomeChair from "./WelcomeChair";
import Footer from "../Components/Footer";
import toast from "react-hot-toast";
import { useFetchQuery } from "../utils/fetchData";
import base from "../utils/base";

const PaperwiseReport = () => {

  const [paper, setPaper] = useState([]);

  const { id } = useParams();

  const url = `${base}/api/v1/submission/getby/conference/${id}`

  const { data, isSuccess, error } = useFetchQuery(url, 'ConferenceID')

  useEffect(() => {
    if (isSuccess && data) {
      console.log(data.message);
      setPaper(data.message);
    }
  }, [isSuccess, data, paper]);


  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }

  // Function to check if all reviewers have provided ratings
  const areReviewsDone = (reviewers) => {
    return reviewers.every((reviewer) => reviewer.rating !== undefined);
  };


  return (
    <div>
      <WelcomeChair id={id} />
      <div className="section-header text-center justify-content-center pt-4 mt-4">
        <h2>Paperwise Report</h2>
        <p>Here you can see your Paperwise Report</p>
      </div>
      <div>
        <div
          className="table-responsive table-scroll px-4 mx-4 py-4 my-4"
          data-mdb-perfect-scrollbar="true"
          style={{ position: "relative", height: "500px" }}
        >
          <table className="table table-striped mb-0 table-bordered">
            <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
              <tr>
                <th scope="col" className="col-md-1">
                  Paper ID
                </th>
                <th scope="col" className="col-md-2">
                  Title
                </th>
                <th scope="col" className="col-md-1">
                  Reviews
                </th>
                <th scope="col" className="col-md-1">
                  Decision
                </th>
                <th scope="col" className="col-md-1">
                  Registration
                </th>
                <th scope="col" className="col-md-1">
                  Information
                </th>
              </tr>
            </thead>
            <tbody>
              {paper.map((data, index) => {
                // data.reviewers.map((reviewer, reviewerIndex) => {
                return <tr key={index} className="schedule-item">
                  <td>{data.paperId}</td>
                  <td>{data.title}</td>
                  <td>{areReviewsDone(data.reviewers) ? 'Done' : 'Pending'}</td>
                  <td>{data.decision}</td>
                  <td>Done and Paid</td>
                  <td>
                    <Link to={`/information/${data._id}`}>
                      <img
                        width="20"
                        height="20"
                        src="https://img.icons8.com/ios-filled/50/info.png"
                        alt="info"
                      />
                    </Link>
                  </td>
                </tr>
                // })
              })
              }
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaperwiseReport;
