import axios from "axios";
import { getToken } from "./LocalStorage"

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': getToken()
}

export {headers};

export const fetchData = async (url) => {
    try {
        const response = await axios.get(url, { headers })
        const data = response.data
        return data
    } catch (error) {
        throw error;
    }
}

export const sendData = async (url, body, customHeaders) => {
    try {
        const headersToUse = customHeaders || headers;
        console.log(headersToUse);
        const response = await axios.post(url, body, { headers: headersToUse });
        const data = response.data;
        return data;
    } catch (error) {
        throw error;
    }
};

export const deleteData = async (url) => {
    try {
        const response = await axios.delete(url, { headers });
        const data = response.data;
        return data;
    } catch (error) {
        throw error;
    }
};
