import React from "react";

const Footer = () => {
  return (
    <div>
      <div className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-custom-red">
        <div className="text-white mb-3 mb-md-0">
          Copyright © 2024. All rights reserved.
        </div>
        <div className="text-white mb-3 mb-md-0">
          Technical Helpline-:
          <a href="tel:+917409882374" className="text-white">
            +91 7409882374
          </a>
        </div>
        <div className="text-white mb-3 mb-md-0">
          Conference Helpline-:
          <a href="tel:+918851610575" className="text-white">
            {" "}
            +91 8851610575
          </a>
        </div>
        <div>
          <a href="#!" className="text-white me-4">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="#!" className="text-white me-4">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="#!" className="text-white me-4">
            <i className="fab fa-google"></i>
          </a>
          <a href="#!" className="text-white">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
