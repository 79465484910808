import React, { useEffect, useState } from "react";
import WelcomeChair from "./WelcomeChair";
import Footer from "../Components/Footer";
import { useParams } from "react-router-dom";
import base from "../utils/base";
import { useFetchQuery } from "../utils/fetchData";
import { headers } from "../utils/http";
import toast from "react-hot-toast";
import axios from "axios";

const ViewPC = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [allUsersStatus, setAllUsersStatus] = useState('');
  const [userDataList, setUserDataList] = useState([]);
  const { id } = useParams();
  const url = `${base}/api/v1/conference/view/rc/${id}`;
  const { isSuccess, error } = useFetchQuery(url, 'ViewRC');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url, { headers });
        const userData = response.data.message;
        setAllUsers(userData);
        setAllUsersStatus(response.data.status);

        // Process user data to get individual conference data
        const userPromises = userData.map(user => getConferenceData(user._id));
        const userDataList = await Promise.all(userPromises);
        setUserDataList(userDataList);
      } catch (error) {
        console.error(error);
        toast.error(error.response?.data?.message || 'Error fetching data', { duration: 3000 });
      }
    };

    fetchData();
  }, [url]);

  const getConferenceData = async (userID) => {
    try {
      const url = `${base}/api/v1/conference/view/user-role/${id}/${userID}`;
      const response = await axios.get(url, { headers });
      const userData = response.data.message;
      return userData;
    } catch (error) {
      console.error(error);
      return {};
    }
  };



  const deleteReviewer = async (userID) => {
    try {
      const confID = id;
      const url = `${base}/api/v1/conference/delete/reviewer/${userID}/${confID}`;
      const response = await axios.delete(url, { headers });
      const status = response.data.status;
      const message = response.data.message;
      console.log(message);
      if (status === 'success') {
        toast.success(message, { duration: 3000 });
      } else {
        toast.error(message, { duration: 3000 });
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message, { duration: 3000 });
    }
  };




  return (
    <div>
      <WelcomeChair id={id} />
      <div className="section-header text-center justify-content-center pt-4 mt-4">
        <h2>View Panel</h2>
        <p>Here you can view & update panel members</p>
      </div>
      <div
        className="table-responsive table-scroll px-4 mx-4 py-4 my-4"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "500px" }}
      >
        {allUsersStatus === 'failed' ? (
          <>
            <table className="table table-striped mb-0 table-bordered">
              <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
                <tr>
                  <th scope="col" className="col-md-3">
                    First Name
                  </th>
                  <th scope="col" className="col-md-2">
                    Last Name
                  </th>
                  <th scope="col" className="col-md-2">
                    Role
                  </th>
                  <th scope="col" className="col-md-1 writing-mode">
                    Delete
                  </th>
                </tr>
              </thead>
            </table>
            <h3 className="text-center my-2">No Conferences</h3>
          </>
        ) : (
          <table className="table table-striped mb-0 table-bordered">
            <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
              <tr>
                <th scope="col" className="col-md-3">
                  First Name
                </th>
                <th scope="col" className="col-md-2">
                  Last Name
                </th>
                <th scope="col" className="col-md-2">
                  Role
                </th>
                <th scope="col" className="col-md-1 writing-mode">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {userDataList.flat().map((data, index) => (
                <tr key={index} className="schedule-item">
                  <td>{data.firstName}</td>
                  <td>{data.lastName}</td>
                  <td className="text-capitalize">{data.role}</td>
                  <td>
                    <button
                      className="table-url"
                      onClick={() => deleteReviewer(data._id)}
                    >
                      <img
                        width="24"
                        height="24"
                        src="https://img.icons8.com/fluency/24/filled-trash.png"
                        alt="filled-trash"
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

      </div>
      <Footer />
    </div>
  );
};

export default ViewPC;
