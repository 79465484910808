import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import bg from "../welcome-bg.jpg";
import HeaderReviewer from "./HeaderReviewer";
import Footer from "../Components/Footer";
import { getToken } from "../utils/LocalStorage";
import base from "../utils/base";
import toast from "react-hot-toast";
import { useFetchQuery } from "../utils/fetchData";

const InformationPaper = () => {

  const [submission, setSubmission] = useState([]);

  const [submissionsStatus, setSubmissionsStatus] = useState([]);

  const [userMapping, setUserMapping] = useState([]); // Mapping of userIds to names


  const { id } = useParams();

  const url = `${base}/api/v1/submission/get/${id}`

  const { data, isSuccess, error } = useFetchQuery(url, 'SubmissionByID')

  useEffect(() => {
    if (isSuccess && data) {
      setSubmission(data.message);
      setSubmissionsStatus(data.status);
    }
  }, [isSuccess, data, setSubmission, setSubmissionsStatus]);

  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }


  // Organizer Details  
  const fetchOrganizerDetails = (userId) => {
    const organizerHost = `${base}/api/v1/auth/get/id/${userId}`;

    fetch(organizerHost, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": getToken()
      }
    }).then(async (res) => {
      const userData = await res.json();
      setUserMapping(prevMapping => [
        ...prevMapping,
        {
          'firstName': userData.message.firstName,
          'lastName': userData.message.lastName,
          'email': userData.message.email,
          'country': userData.message.country,
          'affiliation': userData.message.organizationName,
          'id': userData.message._id,
        }
      ]);
    });
  }


  React.useEffect(() => {
    if (submissionsStatus === 'success') {
      // Clear the previous user mapping
      setUserMapping([]);
  
      // Check if submission.colabAuthors is an array before iterating
      if (Array.isArray(submission.colabAuthors)) {
        // Fetch details for all collaborating authors
        submission.colabAuthors.forEach(fetchOrganizerDetails);
      }
  
      // Fetch details for the main author
      fetchOrganizerDetails(submission.author);
    }
  }, [submission, submissionsStatus]);
  


  return (
    <div>
      <section
        id="welcome"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <HeaderReviewer />
        <div className="container position-relative pt-5" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-6">
              <h2>Submission Id -: 139</h2>
              <p>
                Sed nam ut dolor qui repellendus iusto odit. Possimus inventore
                eveniet accusamus error amet eius aut accusantium et. Non odit
                consequatur repudiandae sequi ea odio molestiae. Enim possimus
                sunt inventore in est ut optio sequi unde.
              </p>
            </div>
            <div className="col-lg-3">
              <h3>Where</h3>
              <p>Downtown Conference Center, New York</p>
            </div>
            <div className="col-lg-3">
              <h3>When</h3>
              <p>
                Monday to Wednesday
                <br />
                10-12 December
              </p>
            </div>
          </div>
        </div>
      </section>
      <div
        className="information table-responsive table-scroll p-5 m-5 pb-0 mb-0"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "700px" }}
      >
        <h2 className="text-center">Submission Details</h2>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Title</th>
              <td scope="row">{submission.title}</td>
            </tr>
            <tr>
              <th scope="col">Paper</th>
              <td scope="row">
                <a
                  href={`${submission.file}`}
                  className="table-url"
                >
                  <img
                    width="24"
                    height="24"
                    src="https://img.icons8.com/plasticine/100/folder-invoices.png"
                    alt="folder-invoices"
                  />
                </a>
              </td>
            </tr>
            <tr>
              <th scope="col">Author Keywords</th>
              <td scope="row">RPA degree job automation procedure robotic</td>
            </tr>
            <tr>
              <th scope="col">Abstract</th>
              <td scope="row">
                {submission.abstract}
              </td>
            </tr>
            <tr>
              <th scope="col" className="bg-table">
                Submitted
              </th>
              <td scope="row">{submission.createdAt}</td>
            </tr>
            <tr>
              <th scope="col">Last Updated</th>
              <td scope="row">{submission.updatedAt}</td>
            </tr>
            <tr>
              <th scope="col">Decision</th>
              <td scope="row">{submission.decision}</td>
            </tr>
          </thead>
        </table>
      </div>
      <h2 className="text-center">Authors</h2>
      <div
        className="table-responsive table-scroll px-5 mx-5 pb-5 mb-5"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "400px" }}
      >
        <table className="table table-striped mb-0">
          <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
            <tr>
              <th scope="col" className="col-md-2">
                First Name
              </th>
              <th scope="col" className="col-md-2">
                Last Name
              </th>
              <th scope="col" className="col-md-3">
                Email
              </th>
              <th scope="col" className="col-md-1">
                Country
              </th>
              <th scope="col" className="col-md-3">
                Affiliation
              </th>
              <th scope="col" className="col-md-1">
                Corresponding
              </th>
            </tr>
          </thead>
          <tbody>
            {userMapping.map((element, index) => (
              <tr className="schedule-item" key={index}>
                <td>{element.firstName}</td>
                <td>{element.lastName}</td>
                <td>{element.email}</td>
                <td>{element.country} </td>
                <td>{element.affiliation}</td>
                <td>Yes</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Footer />
    </div>
  );
};

export default InformationPaper;
