import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import WelcomeChair from "./WelcomeChair";
import Footer from "../Components/Footer";
import base from "../utils/base";
import { useFetchQuery, useMutateQuery } from "../utils/fetchData";
import Spinner from "../Components/Spinner";
import toast from "react-hot-toast";

const AddPC = () => {

  const [emails, setEmails] = useState('');
  const [emailsArray, setEmailsArray] = useState([]);

  const handleAddEmail = () => {
    const emailArray = emails.split(',').map(email => email.trim());
    setEmailsArray(emailArray);
  };

  const { id } = useParams();


  const url2 = `${base}/api/v1/conference/update/add/chairs/${id}`

  const handleSuccess = (data) => {
    toast.success(data.message, { duration: 3000 });
    setTimeout(() => {
      window.location.reload()
    }, 3000);
    setEmails('');
    setEmailsArray([]);
  };

  const handleError = (error) => {
    toast.error(error.response.data.message, { duration: 3000 });
  };

  const { mutate, isPending, isError, error } = useMutateQuery(url2, handleSuccess, handleError)


  const addChairs = async (e) => {
    e.preventDefault();

    const formData = { chairs: emailsArray };

    await mutate(formData);
  };



  const [allChairs, setAllChairs] = useState([]);

  const [allChairsStatus, setAllChairsStatus] = useState()

  const url = `${base}/api/v1/conference/view/chairs/${id}`

  const { data, isSuccess, error: chair } = useFetchQuery(url, 'Chair')

  useEffect(() => {
    if (isSuccess && data) {
      setAllChairs(data.message);
      setAllChairsStatus(data.status);
    }
  }, [isSuccess, data, allChairs, allChairsStatus]);

  if (chair) {
    toast.error(chair.response.data.message, { duration: 3000 });
  }


  return (
    <div>
      <WelcomeChair id={id} />
      <div className="section-header text-center justify-content-center pt-4 mt-4">
        <h2>Add Chair</h2>
        <p>Here you can add chair</p>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <form>
          <div className="form-outline px-4 mx-4 pb-4 mb-4 d-flex flex-row">
            <div className="form-outline col-12">
              <input
                type="text"
                name="emails"
                value={emails}
                onChange={(e) => setEmails(e.target.value)}
                placeholder="Enter email to add chair"
                className="form-control"
                required
              />
            </div>

            <div className="form-outline mx-4">
              <div className="btn btn-custom" onClick={handleAddEmail}>Add</div>
            </div>
            <div className="btn btn-custom" onClick={addChairs}>{isPending ? <Spinner /> : 'Submit'}</div>
          </div>
        </form>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <div
          className="table-responsive table-scroll px-4 mx-4 col-6"
          data-mdb-perfect-scrollbar="true"
          style={{ position: "relative", height: "500px" }}
        >
          {allChairsStatus === 'failed' ?
            <table className="table table-striped mb-0 table-bordered">
              <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
                <tr>
                  <th scope="col" className="col-md-4">
                    PC Member
                  </th>
                  <th scope="col" className="col-md-3">
                    Role
                  </th>
                </tr>
              </thead>
              <h3>No Chairs</h3>
            </table>
            :
            <table className="table table-striped mb-0 table-bordered">
              <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
                <tr>
                  <th scope="col" className="col-md-4">
                    PC Member
                  </th>
                  <th scope="col" className="col-md-3">
                    Role
                  </th>
                </tr>
              </thead>
              {allChairs.map((data, index) => {
                return <tbody key={index}>
                  <tr className="schedule-item">
                    <td>{data.firstName} {data.lastName}</td>
                    <td>Chairs</td>
                  </tr>
                </tbody>
              })
              }
            </table>}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AddPC;
