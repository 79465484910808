import React from "react";
import Footer from "./Footer";
import HeaderBackend from "./HeaderBackend";
import bg from "../welcome-bg.jpg";
import base from "../utils/base";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useMutateQuery } from "../utils/fetchData";
import Spinner from "./Spinner";

const CreateConf = () => {

  const navigate = useNavigate();

  const url = `${base}/api/v1/conference/create`

  const handleSuccess = (data) => {
    toast.success(data.message, { duration: 3000 });
    navigate(`/welcome`)
  };

  const handleError = (data) => {
    toast.error(data.response.data.message, { duration: 3000 });
  };

  const { mutate, isPending, isError, error } = useMutateQuery(url, handleSuccess, handleError)


  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      type: e.target.type.value,
      conferenceTitle: e.target.conferenceTitle.value,
      acronym: e.target.acronym.value,
      webPage: e.target.webPage.value,
      modeOfConference: e.target.modeOfConference.value,
      venue: e.target.venue.value,
      city: e.target.city.value,
      country: e.target.country.value,
      estimatedNumberOfSubmissions: e.target.estimatedNumberOfSubmissions.value,
      startDate: e.target.startDate.value,
      endDate: e.target.endDate.value,
      primaryArea: e.target.primaryArea.value,
      secondaryArea: e.target.secondaryArea.value,
      areaNotes: e.target.areaNotes.value,
      otherInformation: e.target.otherInformation.value,
      tc: e.target.tc.checked,
    };

    await mutate(formData);
  };



  return (
    <>
      <div>
        <section
          id="welcome"
          style={{
            backgroundImage: `url(${bg})`,
          }}
        >
          <HeaderBackend />
          <div
            className="container position-relative pt-5 text-center"
            data-aos="fade-up"
          >
            <h1>Create New Conference</h1>
            <h3>Organizer Console</h3>
          </div>
        </section>
        <div className="background-radial-gradient">
          <section id="schedule" className="bg-glass">
            <div className="container" data-aos="fade-up">
              <div className="col-lg-16 mb-5 mb-lg-0 position-relative">
                <div className="card bg-glass">
                  <div className="card-body px-4 py-5 px-md-5">
                    <form onSubmit={handleSubmit}>
                      <div className="bg-custom bg-gradient text-dark p-3 mb-4 border-custom">
                        <div className="form-outline mb-4">
                          <h5>Installation Type</h5>
                          <hr />
                          <label htmlFor="type" className="form-label required">
                            Type:
                          </label>
                          <select name="type" className="form-control">
                            <option value="conference">Conference</option>
                            <option value="book">Book</option>
                            <option value="usedForTeaching">
                              Used for teaching
                            </option>
                            <option value="journal">Journal</option>
                          </select>
                        </div>
                      </div>
                      <div className="bg-custom bg-gradient text-dark p-3 mb-4 border-custom">
                        <div className="form-outline mb-4">
                          <h5>Title & Acronym</h5>
                          <hr />
                          <label
                            htmlFor="conferenceTitle"
                            className="form-label required"
                          >
                            Conference Title
                          </label>
                          <input
                            type="text"
                            name="conferenceTitle"
                            className="form-control"
                            required
                          />
                        </div>

                        <div className="form-outline mb-4">
                          <label
                            htmlFor="acronym"
                            className="form-label required"
                          >
                            Acronym
                          </label>
                          <input
                            type="text"
                            name="acronym"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>

                      <div className="bg-custom bg-gradient text-dark p-3 mb-4 border-custom">
                        <div className="form-outline mb-4">
                          <h5>Conference Information</h5>
                          <hr />
                          <label
                            htmlFor="webPage"
                            className="form-label required"
                          >
                            Web Page
                          </label>
                          <input
                            type="text"
                            name="webPage"
                            className="form-control"
                            required
                          />
                        </div>

                        <div className="form-outline mb-4">
                          <label
                            htmlFor="modeOfConference"
                            className="form-label required"
                          >
                            Mode Of Conference
                          </label>
                          {/* <input
                            type="text"
                            name="modeOfConference"
                            className="form-control"
                            required
                          /> */}
                          <select
                            name="modeOfConference"
                            className="form-select"
                          >
                            <option value="select" className="">
                              Select
                            </option>
                            <option value="hybrid" className="">
                              Hybrid
                            </option>
                            <option value="online" className="">
                              Online
                            </option>
                            <option value="offline" className="">
                              Offline
                            </option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label
                            htmlFor="venue"
                            className="form-label required"
                          >
                            Venue
                          </label>
                          <input
                            type="text"
                            name="venue"
                            className="form-control"
                            required
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label htmlFor="city" className="form-label required">
                            City
                          </label>
                          <input
                            type="text"
                            name="city"
                            className="form-control"
                            required
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label
                            htmlFor="country"
                            className="form-label required"
                          >
                            Country/Region
                          </label>
                          <input
                            type="text"
                            name="country"
                            className="form-control"
                            required
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label
                            htmlFor="estimatedNumberOfSubmissions"
                            className="form-label required"
                          >
                            Estimated number of submissions
                          </label>
                          <input
                            type="text"
                            name="estimatedNumberOfSubmissions"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>

                      <div className="bg-custom bg-gradient text-dark p-3 mb-4 border-custom">
                        <div className="form-outline mb-4">
                          <h5>Conference Dates</h5>
                          <hr />
                          <label
                            htmlFor="startDate"
                            className="form-label required"
                          >
                            Start Date
                          </label>
                          <input
                            type="date"
                            name="startDate"
                            className="form-control"
                            required
                          />
                        </div>

                        <div className="form-outline mb-4">
                          <label
                            htmlFor="endDate"
                            className="form-label required"
                          >
                            End Date
                          </label>
                          <input
                            type="date"
                            name="endDate"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>

                      <div className="bg-custom bg-gradient text-dark p-3 mb-4 border-custom">
                        <div className="form-outline mb-4">
                          <h5>Research Areas</h5>
                          <hr />
                          <label
                            htmlFor="primaryArea"
                            className="form-label required"
                          >
                            Primary Area
                          </label>
                          <select name="primaryArea" className="form-control">
                            <option value="Select">Select</option>
                            <option value="Accounting and Finance">
                              Accounting and Finance
                            </option>
                            <option value="Biological Sciences">
                              Biological Sciences
                            </option>
                            <option value="Business and Management">
                              Business and Management
                            </option>
                            <option value="Chemistry">Chemistry</option>
                            <option value="Civil Engineering">
                              Civil Engineering
                            </option>
                            <option value="Computing">Computing</option>
                            <option value="Earth and Environmental Sciences">
                              Earth and Environmental Sciences
                            </option>
                            <option value="Economics">Economics</option>
                            <option value="Education Science">
                              Education Science
                            </option>
                            <option value="Energy">Energy</option>
                            <option value="Engineering">Engineering</option>
                            <option value="Genomics and Bioinformatics">
                              Genomics and Bioinformatics
                            </option>
                            <option value="Health Sciences">
                              Health Sciences
                            </option>
                            <option value="Language and Linguistics">
                              Language and Linguistics
                            </option>
                            <option value="Mathematics and Statistics">
                              Mathematics and Statistics
                            </option>
                            <option value="Mechanical Engineering">
                              Mechanical Engineering
                            </option>
                            <option value="Physics">Physics</option>
                            <option value="Social Sciences">
                              Social Sciences
                            </option>
                            <option value="Technology">Technology</option>
                          </select>
                        </div>

                        <div className="form-outline mb-4">
                          <label
                            htmlFor="secondaryArea"
                            className="form-label required"
                          >
                            Secondary Area
                          </label>
                          <select name="secondaryArea" className="form-control">
                            <option value="Select">Select</option>
                            <option value="Accounting and Finance">
                              Accounting and Finance
                            </option>
                            <option value="Biological Sciences">
                              Biological Sciences
                            </option>
                            <option value="Business and Management">
                              Business and Management
                            </option>
                            <option value="Chemistry">Chemistry</option>
                            <option value="Civil Engineering">
                              Civil Engineering
                            </option>
                            <option value="Computing">Computing</option>
                            <option value="Earth and Environmental Sciences">
                              Earth and Environmental Sciences
                            </option>
                            <option value="Economics">Economics</option>
                            <option value="Education Science">
                              Education Science
                            </option>
                            <option value="Energy">Energy</option>
                            <option value="Engineering">Engineering</option>
                            <option value="Genomics and Bioinformatics">
                              Genomics and Bioinformatics
                            </option>
                            <option value="Health Sciences">
                              Health Sciences
                            </option>
                            <option value="Language and Linguistics">
                              Language and Linguistics
                            </option>
                            <option value="Mathematics and Statistics">
                              Mathematics and Statistics
                            </option>
                            <option value="Mechanical Engineering">
                              Mechanical Engineering
                            </option>
                            <option value="Physics">Physics</option>
                            <option value="Social Sciences">
                              Social Sciences
                            </option>
                            <option value="Technology">Technology</option>
                          </select>
                        </div>
                        <div className="form-outline mb-4">
                          <label htmlFor="areaNotes" className="form-label">
                            Area Notes
                          </label>
                          <textarea
                            type="text"
                            name="areaNotes"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="bg-custom bg-gradient text-dark p-3 mb-4 border-custom">
                        <div className="form-outline mb-4">
                          <h5>Organizer Information</h5>
                          <hr />
                          {/* <label
                            htmlFor="yourRole"
                            className="form-label required"
                          >
                            Your Role
                          </label>
                          <input
                            type="text"
                            name="yourRole"
                            className="form-control"
                            required
                          /> */}
                        </div>

                        <div className="form-outline mb-4">
                          <label
                            htmlFor="otherInformation"
                            className="form-label"
                          >
                            Any other Information
                          </label>
                          <input
                            type="text"
                            name="otherInformation"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="d-flex justify-content-between align-items-center">
                        <div className="form-check mb-3">
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            name="tc"
                          />
                          <label className="form-check-label form-label required">
                            I agree to Conf Chair Terms and Conditions.
                          </label>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary btn-block mb-2"
                      >
                        {isPending ? <Spinner /> : "Submit"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CreateConf;
