import React from 'react'
import PulseLoader from 'react-spinners/PulseLoader';

const Spinner = () => {

    const spinner = (
        <PulseLoader color="white" size={8} aria-label="Loading Spinner" data-testid="loader" />
    );

    return (
        <div>{spinner}</div>
    )
}

export default Spinner