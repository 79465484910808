import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Welcome from "./Welcome";
import Footer from "./Footer";
import toast from "react-hot-toast";
import base from "../utils/base";
import { useFetchQuery } from "../utils/fetchData";

const MyAccount = () => {
  const [user, setUser] = useState([]);

  const url = `${base}/api/v1/auth/me`;

  const { data, isSuccess, error } = useFetchQuery(url, "User");

  useEffect(() => {
    if (isSuccess && data) {
      setUser(data.data);
    }
  }, [isSuccess, data, user]);

  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }

  return (
    <div>
      <Welcome />
      <div
        className="information table-responsive table-scroll p-5 m-5 pb-0 mb-0"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "700px" }}
      >
        <div className="d-flex flex-row justify-content-between">
          <h2 className="text-center">My Account</h2>

          <Link to="/my-account-edit">
            <div className="d-flex flex-row">
              <img
                width="20"
                height="20"
                className="d-flex flex-row justify-content-end"
                src="https://img.icons8.com/color/48/edit.png"
                alt="edit"
              />
              <p>Edit</p>
            </div>
          </Link>
        </div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">First Name</th>
              <td scope="row">{user.firstName}</td>
            </tr>
            <tr>
              <th scope="col">Last Name</th>
              <td scope="row">{user.lastName}</td>
            </tr>
            <tr>
              <th scope="col">Email</th>
              <td scope="row">{user.email}</td>
            </tr>
            <tr>
              <th scope="col">Alternate Email</th>
              <td scope="row">{user.alternateEmail}</td>
            </tr>
            <tr>
              <th scope="col">Mobile Number</th>
              <td scope="row">{user.mobileNumber}</td>
            </tr>
            <tr>
              <th scope="col">Alternate Mobile Number</th>
              <td scope="row">{user.alternateMobileNumber}</td>
            </tr>
            <tr>
              <th scope="col" className="bg-table">
                Organization Name
              </th>
              <td scope="row"> {user.organizationName}</td>
            </tr>
            <tr>
              <th scope="col">City</th>
              <td scope="row"> {user.city}</td>
            </tr>
            <tr>
              <th scope="col">State</th>
              <td scope="row"> {user.state}</td>
            </tr>
            <tr>
              <th scope="col">Country</th>
              <td scope="row"> {user.country}</td>
            </tr>
            <tr>
              <th scope="col">Pincode</th>
              <td scope="row"> {user.pincode}</td>
            </tr>
            <tr>
              <th scope="col">Facebook Id</th>
              <td scope="row"> {user.facebookId}</td>
            </tr>
            <tr>
              <th scope="col">Linkedin Id</th>
              <td scope="row"> {user.linkedinId}</td>
            </tr>
            <tr>
              <th scope="col">Twitter Id</th>
              <td scope="row"> {user.twitterId}</td>
            </tr>
            <tr>
              <th scope="col">Google Scholar Id</th>
              <td scope="row"> {user.googleScholarId}</td>
            </tr>
            <tr>
              <th scope="col">Semantic Scholar Id</th>
              <td scope="row"> {user.semanticScholarId}</td>
            </tr>
            <tr>
              <th scope="col">DBLP Id</th>
              <td scope="row"> {user.dblpId}</td>
            </tr>
            <tr>
              <th scope="col">ORCID Id</th>
              <td scope="row"> {user.orcidId}</td>
            </tr>
          </thead>
        </table>
      </div>
      <Footer />
    </div>
  );
};

export default MyAccount;
