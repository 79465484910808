import React from 'react'
import App from './App'
import DismissableToast from './Components/DismissableToast'

const Main = () => {
    return (
        <>
            <DismissableToast />
            <App />
        </>
    )
}

export default Main