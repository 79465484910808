import React from "react";
import WelcomeChair from "./WelcomeChair";

const CreateAgenda = () => {
  return (
    <div>
      <WelcomeChair />
      <div className="section-header text-center justify-content-center pt-4 mt-4">
        <h2>Schedule Builder</h2>
        <p>Here you can create your Agenda</p>
      </div>
      <div>
        <div className="background-radial-gradient">
          <section id="schedule" className="bg-glass">
            <div className="container" data-aos="fade-up">
              <div className="col-lg-16 mb-5 mb-lg-0 position-relative">
                <div className="card bg-glass">
                  <div className="card-body px-4 py-5 px-md-5">
                    <form>
                      <div className="bg-custom bg-gradient text-dark p-5 mb-4 border-custom">
                        <div className="form-outline mb-4">
                          <h5>Logo & Sponsor</h5>
                          <hr />
                          <label className="form-label required">Logo</label>
                          <div className="d-flex flex-row">
                            <div className="circle-container">
                              <img
                                width="30"
                                height="30"
                                src="https://img.icons8.com/ios-glyphs/30/add-image.png"
                                alt="add-image"
                                className="circle-img"
                              />
                            </div>
                            <input
                              type="file"
                              name="logo"
                              className="form-control mt-2"
                              required
                            />
                          </div>
                        </div>

                        <div className="form-outline mb-4">
                          <label className="form-label required">Sponsor</label>
                          <div className="d-flex flex-row">
                            <div className="circle-container">
                              <img
                                width="30"
                                height="30"
                                src="https://img.icons8.com/ios-glyphs/30/add-image.png"
                                alt="add-image"
                                className="circle-img"
                              />
                            </div>
                            <input
                              type="file"
                              name="logo"
                              className="form-control mt-2"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="bg-custom bg-gradient text-dark p-5 mb-4 border-custom">
                        <div className="form-outline mb-4">
                          <h5>Title & Acronym</h5>
                          <hr />
                          <label className="form-label required">Title</label>
                          <input
                            type="text"
                            name="title"
                            placeholder="International Conference on Advanced Computing & Systems"
                            className="form-control"
                            required
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label className="form-label required">Acronym</label>
                          <input
                            type="text"
                            name="abstract"
                            placeholder="IEEE-24"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div className="bg-custom bg-gradient text-dark p-5 mb-4 border-custom">
                        <div className="form-outline mb-4">
                          <h5>Date, Time, Venue</h5>
                          <hr />
                          <label className="form-label required">Date</label>
                          <input
                            type="date"
                            name="title"
                            className="form-control"
                            required
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label className="form-label required">Time</label>
                          <input
                            type="time"
                            name="abstract"
                            className="form-control"
                            required
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label className="form-label required">Venue</label>
                          <input
                            type="text"
                            name="abstract"
                            className="form-control"
                            required
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label className="form-label required">
                            Platform
                          </label>
                          <input
                            type="text"
                            name="abstract"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div className="bg-custom bg-gradient text-dark p-5 mb-4 border-custom">
                        <div className="form-outline mb-4">
                          <h5>Activity</h5>
                          <p>You may add your activity</p>
                          <hr />
                          <div className="row">
                            <div className="col-md-2 mb-4">
                              <div className="form-outline">
                                <input
                                  type="text"
                                  name="emails"
                                  placeholder="Enter time"
                                  className="form-control"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-3 mb-4">
                              <div className="form-outline">
                                <input
                                  type="text"
                                  name="emails"
                                  placeholder="Enter Activity"
                                  className="form-control"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-3 mb-4">
                              <div className="form-outline">
                                <input
                                  type="text"
                                  name="emails"
                                  placeholder="Enter Presenter"
                                  className="form-control"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-4 mb-4">
                              <div className="form-outline">
                                <div className="btn btn-secondary">Add</div>
                              </div>
                            </div>
                          </div>
                          <table className="table">
                            <caption>List of Activities</caption>
                            <thead>
                              <tr>
                                <th scope="col">Time</th>
                                <th scope="col">Activity</th>
                                <th scope="col">Presenter</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row"></th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="bg-custom bg-gradient text-dark p-5 mb-4 border-custom">
                        <div className="form-outline mb-4">
                          <h5>Schedule</h5>
                          <p>You may add your paper schedule</p>
                          <hr />
                          <div className="row">
                            <div className="col-md-2 mb-4">
                              <div className="form-outline">
                                <input
                                  type="text"
                                  name="emails"
                                  placeholder="Enter start time"
                                  className="form-control"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-2 mb-4">
                              <div className="form-outline">
                                <input
                                  type="text"
                                  name="emails"
                                  placeholder="Enter end time"
                                  className="form-control"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-2 mb-4">
                              <div className="form-outline">
                                <input
                                  type="text"
                                  name="emails"
                                  placeholder="Enter Session"
                                  className="form-control"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-3 mb-4">
                              <div className="form-outline">
                                <input
                                  type="text"
                                  name="emails"
                                  placeholder="Enter Activity"
                                  className="form-control"
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-md-3 mb-4">
                              <div className="form-outline">
                                <input
                                  type="text"
                                  name="emails"
                                  placeholder="Enter Joining Link"
                                  className="form-control"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-row flex-wrap">
                            <h4 className="col-2 text-center mx-2 p-2 bg-border-custom">
                              IEEE 001
                            </h4>
                          </div>

                          <div className="col-md-4 mb-4">
                            <div className="form-outline">
                              <div className="btn btn-custom1">Add</div>
                            </div>
                          </div>
                          <table className="table">
                            <caption>Schedule</caption>
                            <thead>
                              <tr>
                                <th scope="col">Time</th>
                                <th scope="col">Session</th>
                                <th scope="col">Activity</th>
                                <th scope="col">Papers</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row"></th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <button
                        className="btn btn-custom1 btn-block mb-2"
                        type="submit"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CreateAgenda;
