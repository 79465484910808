import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import WelcomeChair from "./WelcomeChair";
import Footer from "../Components/Footer";
import { getToken } from "../utils/LocalStorage";
import base from "../utils/base";
import { useFetchQuery } from "../utils/fetchData";
import toast from "react-hot-toast";

const ReviewerwiseReport = () => {
  const [reviewers, setReviewers] = useState([]);
  const [reviewersStatus, setReviewersStatus] = useState([]);

  const { id } = useParams();

  const url = `${base}/api/v1/conference/view/reviewers/${id}`;

  const { data, isSuccess, error } = useFetchQuery(url, "SubmissionID");

  useEffect(() => {
    if (isSuccess && data) {
      setReviewers(data.message);
      setReviewersStatus(data.status);
    }
  }, [isSuccess, data, reviewers, reviewersStatus]);

  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }

  return (
    <div>
      <WelcomeChair id={id} />
      <div className="section-header text-center justify-content-center pt-4 mt-4">
        <h2>Reviewerwise Report</h2>
        <p>Here you can see your Reviewerwise Report</p>
      </div>
      <div>
        <div
          className="table-responsive table-scroll px-4 mx-4 py-4 my-4"
          data-mdb-perfect-scrollbar="true"
          style={{ position: "relative", height: "500px" }}
        >
          {reviewersStatus === "failed" ? (
            <table className="table table-striped mb-0 table-bordered">
              <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
                <tr>
                  <th scope="col" className="col-md-2">
                    Name
                  </th>
                  <th scope="col" className="col-md-3">
                    Email
                  </th>
                  <th scope="col" className="col-md-2">
                    Assigned
                  </th>
                  <th scope="col" className="col-md-2">
                    Reviewed
                  </th>
                  <th scope="col" className="col-md-2">
                    Pending
                  </th>
                  <th scope="col" className="col-md-1">
                    Information
                  </th>
                </tr>
              </thead>
              <h4 className="text-center my-2">No Active Role Found</h4>
            </table>
          ) : (
            <table className="table table-striped mb-0 table-bordered">
              <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
                <tr>
                  <th scope="col" className="col-md-2">
                    Name
                  </th>
                  <th scope="col" className="col-md-3">
                    Email
                  </th>
                  <th scope="col" className="col-md-2">
                    Assigned
                  </th>
                  <th scope="col" className="col-md-2">
                    Reviewed
                  </th>
                  <th scope="col" className="col-md-2">
                    Pending
                  </th>
                  <th scope="col" className="col-md-1">
                    Information
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(reviewers) &&
                  reviewers.map((data, index) => {
                    const reviewerData = data.reviewerData[0]; // Assuming there is only one reviewer data for simplicity
                    return (
                      <tr key={index} className="schedule-item">
                        <td>{data.firstName + " " + data.lastName}</td>
                        <td>{data.email}</td>
                        <td>{reviewerData ? reviewerData.assigned : 0}</td>
                        <td>
                          {reviewerData
                            ? reviewerData.assigned - reviewerData.pending
                            : 0}
                        </td>
                        <td>{reviewerData ? reviewerData.pending : 0}</td>
                        <td>
                          <Link to={`/information-reviewer/${data._id}`}>
                            <img
                              width="20"
                              height="20"
                              src="https://img.icons8.com/ios-filled/50/info.png"
                              alt="info"
                            />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ReviewerwiseReport;
