import React, { useEffect, useState } from "react";
import bg from "../welcome-bg.jpg";
import HeaderChair from "./HeaderChair";
import { Link, useParams } from "react-router-dom";
import Footer from "../Components/Footer";
import base from "../utils/base";
import toast from "react-hot-toast";
import { useFetchQuery } from "../utils/fetchData";
import { getToken } from "../utils/LocalStorage";
import WelcomeChair from "./WelcomeChair";

const Information = () => {
  const [submission, setSubmission] = useState([]);

  const [submissionReviewer, setSubmissionReviewer] = useState([]);

  const [submissionsStatus, setSubmissionsStatus] = useState([]);

  const [userMapping, setUserMapping] = useState([]); // Mapping of userIds to names


  const { id1, id } = useParams();

  const url = `${base}/api/v1/submission/get/${id}`

  const { data, isSuccess, error } = useFetchQuery(url, 'SubmissionByID')

  useEffect(() => {
    if (isSuccess && data) {
      console.log(data);
      setSubmission(data.message);
      setSubmissionReviewer(data.message.reviewers)
      setSubmissionsStatus(data.status);
    }
  }, [isSuccess, data, setSubmission, setSubmissionsStatus]);

  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }


  // Organizer Details  
  const fetchOrganizerDetails = (userId) => {
    const organizerHost = `${base}/api/v1/auth/get/id/${userId}`;

    fetch(organizerHost, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": getToken()
      }
    }).then(async (res) => {
      const userData = await res.json();
      setUserMapping(prevMapping => [
        ...prevMapping,
        {
          'firstName': userData.message.firstName,
          'lastName': userData.message.lastName,
          'email': userData.message.email,
          'country': userData.message.country,
          'affiliation': userData.message.organizationName,
          'id': userData.message._id,
        }
      ]);
    });
  }


  React.useEffect(() => {
    if (submissionsStatus === 'success') {
      setUserMapping([]);

      submission.authors.forEach(fetchOrganizerDetails);
      fetchOrganizerDetails(submission.author);

      submissionReviewer.forEach(reviewer => {
        const isAuthor = submission.authors.includes(reviewer.user);
        const isMainAuthor = submission.author === reviewer.user;

        if (!isAuthor && !isMainAuthor) {
          fetchOrganizerDetails(reviewer.user);
        }
      });
    }
  }, [submission, submissionReviewer, submissionsStatus]);



  return (
    <div>
      <WelcomeChair id={id1} />
      <div
        className="information table-responsive table-scroll p-5 m-5 pb-0 mb-0"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "700px" }}
      >
        <h2 className="text-center">Submission Details</h2>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Title</th>
              <td scope="row">{submission.title}</td>
            </tr>
            <tr>
              <th scope="col">Paper</th>
              <td scope="row">
                <a href={`${submission.file}`} className="table-url">
                  <img
                    width="24"
                    height="24"
                    src="https://img.icons8.com/plasticine/100/folder-invoices.png"
                    alt="folder-invoices"
                  />
                </a>
              </td>
            </tr>
            <tr>
              <th scope="col">Author Keywords</th>
              <td scope="row">RPA degree job automation procedure robotic</td>
            </tr>
            <tr>
              <th scope="col">Abstract</th>
              <td scope="row">{submission.abstract}</td>
            </tr>
            <tr>
              <th scope="col" className="bg-table">
                Submitted
              </th>
              <td scope="row">{submission.createdAt}</td>
            </tr>
            <tr>
              <th scope="col">Last Updated</th>
              <td scope="row">{submission.updatedAt}</td>
            </tr>
            <tr>
              <th scope="col">Decision</th>
              <td scope="row">{submission.decision}</td>
            </tr>
          </thead>
        </table>
      </div>
      <h2 className="text-center">Authors</h2>
      <div
        className="table-responsive table-scroll px-5 mx-5 pb-5 mb-5"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "400px" }}
      >
        <table className="table table-striped mb-0">
          <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
            <tr>
              <th scope="col" className="col-md-2">
                First Name
              </th>
              <th scope="col" className="col-md-2">
                Last Name
              </th>
              <th scope="col" className="col-md-3">
                Email
              </th>
              <th scope="col" className="col-md-1">
                Country
              </th>
              <th scope="col" className="col-md-3">
                Affiliation
              </th>
              <th scope="col" className="col-md-1">
                Corresponding
              </th>
            </tr>
          </thead>
          <tbody>
            {userMapping
              .filter(element => !submissionReviewer.some(reviewer => reviewer.user === element.id)) // Exclude reviewers
              .map((element, index) => (
                <tr className="schedule-item" key={index}>
                  <td>{element.firstName}</td>
                  <td>{element.lastName}</td>
                  <td>{element.email}</td>
                  <td>{element.country} </td>
                  <td>{element.affiliation}</td>
                  <td>Yes</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <h2 className="text-center">Reviewers</h2>
      <div
        className="information table-responsive table-scroll px-5 mx-5 pb-5 mb-5"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "500px" }}
      >
        {submissionReviewer.map((data, index) => {
          const userDetail = userMapping.find(user => user.id === data.user);

          return <table key={index} className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" className="col-3">
                  PC Member
                </th>
                <td scope="row">
                  <b>{userDetail ? userDetail.firstName : data.user}</b>
                </td>
              </tr>
              <tr>
                <th scope="col" className="col-3">
                  Reviewer's Confidence
                </th>
                <td scope="row">{data.rating ? data.rating : "-"}</td>
              </tr>
              <tr>
                <th scope="col" className="col-3">
                  Overall Evaluation
                </th>
                <td scope="row">
                  {data.comment ? data.comment : '-'}
                </td>
              </tr>

              <tr>
                <th scope="col" className="col-3">
                  Confidential remarks for the program committee
                </th>
                <td scope="row">
                  {data.remark ? data.remark : "-"}
                </td>
              </tr>
            </thead>
          </table>
        })
        }
      </div>
      <Footer />
    </div>
  );
};

export default Information;
