import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import WelcomeChair from "./WelcomeChair";
import Footer from "../Components/Footer";
import { useFetchQuery } from "../utils/fetchData";
import toast from "react-hot-toast";
import { getToken } from "../utils/LocalStorage";
import base from "../utils/base";

const Decision1 = () => {

  const [papers, setPapers] = useState([]);
  const [papersStatus, setPapersStatus] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [reviewersStatus, setReviewersStatus] = useState([]);
  const [isLoading, setIsLoading] = useState([]);


  const [userMapping, setUserMapping] = useState([]); // Mapping of userIds to names

  const { id } = useParams();

  const url = `${base}/api/v1/submission/getby/conference/${id}`

  const { data, isSuccess, error } = useFetchQuery(url, 'SubmissionUsingConference')

  useEffect(() => {
    if (isSuccess && data) {
      console.log(data.message);
      setPapers(data.message);
      setPapersStatus(data.status);
    }
  }, [isSuccess, data, papers, papersStatus]);

  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }


  const url2 = `${base}/api/v1/conference/view/reviewers/${id}`

  const { data: reviewerData, isSuccess: reviewerSuccess, error: reviewerError } = useFetchQuery(url2, 'ConferencveReviewer')

  useEffect(() => {
    if (reviewerSuccess && reviewerData) {
      // console.log(reviewerData);
      setReviewers(reviewerData.message);
      setReviewersStatus(reviewerData.status);
    }
  }, [reviewerSuccess, reviewerData, papers, papersStatus]);

  if (reviewerError) {
    toast.error(reviewerError.response.data.message, { duration: 3000 });
  }



  const handleAccept = async (id) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${base}/api/v1/submission/update/submission/${id}/accept`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': `${getToken()}`
        },
      });
      const data = await response.json();
      if (data.status === 'failed') {
        toast.error(data.message, { duration: 3000 });
      } else {
        toast.success(data.message, { duration: 3000 });
        console.log(data);
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred', { duration: 3000 });
    } finally {
      setIsLoading(false);
    }
  };

  const handleReject = async (id) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${base}/api/v1/submission/update/submission/${id}/reject`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': `${getToken()}`
        },
      });
      const data = await response.json();
      if (data.status === 'failed') {
        toast.error(data.message, { duration: 3000 });
      } else {
        toast.success(data.message, { duration: 3000 });
        console.log(data);
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred', { duration: 3000 });
    } finally {
      setIsLoading(false);
    }
  };


  // Organizer Details  
  const fetchOrganizerDetails = (userId) => {
    const organizerHost = `${base}/api/v1/auth/get/id/${userId}`;

    fetch(organizerHost, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: getToken(),
      },
    }).then(async (res) => {
      const userData = await res.json();
      setUserMapping((prevMapping) => ({
        ...prevMapping,
        [userId]: userData.message.firstName,
      }));
    });
  };

  React.useEffect(() => {
    if (papersStatus === "success") {
      papers.forEach((data) => {
        for (let i = 0; i < data.authors.length; i++) {
          const element = data.authors[i];
          fetchOrganizerDetails(element);
        }
        fetchOrganizerDetails(data.author);
      });

      papers.forEach((data) => {
        for (let i = 0; i < data.reviewers.length; i++) {
          const element = data.reviewers[i].user;
          fetchOrganizerDetails(element);
        }
      });
    }

  }, [papers, papersStatus]);





  return (
    <div>
      <WelcomeChair id={id}/>
      <div className="section-header text-center justify-content-center pt-4 mt-4">
        <h2>Decision</h2>
        <p>Here you can give the decision</p>
      </div>
      {papers.map((paper, index) => (
        <div key={index}
          className="table-responsive table-scroll px-2 mx-2 pt-2 mt-4"
          data-mdb-perfect-scrollbar="true"
          style={{ position: "relative", height: "400px" }}
        >
          <table className="table mb-0">
            <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
              <tr>
                <th scope="col" className="col-md-1">
                  {paper.paperId}
                </th>
                <th scope="col" className="col-md-3">
                  {userMapping[paper.author]},
                  {paper.authors.map((element, index) => (
                    <React.Fragment key={index}>
                      {userMapping[element]}
                      {index < paper.authors.length - 1 ? ", " : ""}
                    </React.Fragment>
                  )) || "Loading..."}
                </th>
                <th scope="col" className="col-md-3">
                  {paper.title}
                </th>
                <th scope="col" className="col-md-1">
                  <Link to={`/information/${id}/${paper._id}`}>
                    <img
                      width="20"
                      height="20"
                      src="https://img.icons8.com/ios-filled/50/FFFFFF/info.png"
                      alt="info"
                    />
                  </Link>
                </th>
                <th scope="col" className="col-md-3"></th>
              </tr>
            </thead>
            <tbody>
              {paper.reviewers.map((data, index2) => {
                return <tr className="schedule-item" key={index2}>
                  <td>{index2+1}</td>
                  <td>{userMapping[data.user]}</td>
                  <td>{data.rating}</td>
                  <td>{data.comment}</td>
                  <td>{data.remark}</td>
                </tr>
              })
              }
            </tbody>
          </table>
          <div className="d-flex flex-row justify-center mx-auto mt-2">
            <button className="btn btn-custom1 mx-3" onClick={() => handleAccept(paper._id)}>Accept</button>
            <button className="btn btn-custom1 mx-3" onClick={() => handleReject(paper._id)}>Reject</button>
            <button className="btn btn-custom1 mx-3">Update</button>
          </div>
        </div>
      ))
      }
      <Footer />
    </div >
  );
};

export default Decision1;
