import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import WelcomeChair from "./WelcomeChair";
import { useParams } from "react-router-dom";
import { useFetchQuery } from "../utils/fetchData";
import toast from "react-hot-toast";
import base from "../utils/base";
import { getToken } from "../utils/LocalStorage";

const Email = () => {
  const [papers, setPapers] = useState([]);

  const { id } = useParams();

  const url = `${base}/api/v1/submission/get/all/submission/${id}`;

  const { data, isSuccess, error } = useFetchQuery(
    url,
    "AllConferencesSubmission"
  );

  useEffect(() => {
    if (data && isSuccess) {
      console.log(data.message);
      setPapers(data.message);
    }
  }, [isSuccess, data, papers]);

  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }

  const [selectedPapers, setSelectedPapers] = useState([]);

  const [selectedEmails, setSelectedEmails] = useState([]);

  const handlePaperSelection = (paper) => {
    const authorEmail = paper.author.email;
    const authorsEmails = paper.authors.map((author) => author.email);

    const allEmails = [authorEmail, ...authorsEmails];

    const isSelected = selectedPapers.some(
      (selectedPaper) => selectedPaper.title === paper.title
    );

    if (isSelected) {
      setSelectedPapers((prevSelectedPapers) =>
        prevSelectedPapers.filter(
          (selectedPaper) => selectedPaper.title !== paper.title
        )
      );
    } else {
      setSelectedPapers((prevSelectedPapers) => [
        ...prevSelectedPapers,
        {
          emails: allEmails,
        },
      ]);
    }
  };

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [formData, setFormData] = useState({
    to: { selectedEmails },
    subject: "",
    message: "",
    attachment: "",
  });

  const emailTemplates = [
    {
      id: 1,
      name: "Template 1",
      to: selectedEmails,
      subject: "Subject 1",
      message: "Message 1",
      attachment: "file1.pdf",
    },
    {
      id: 2,
      name: "Template 2",
      to: selectedEmails,
      subject: "Subject 2",
      message: "Message 2",
      attachment: "file2.pdf",
    },
    {
      id: 3,
      name: "Template 3",
      to: selectedEmails,
      subject: "Subject 3",
      message: "Message 3",
      attachment: "file3.pdf",
    },
  ];

  const handleTemplateSelection = (template) => {
    setSelectedTemplate(template);
    setFormData({
      to: template.to,
      subject: template.subject,
      message: template.message,
      attachment: template.attachment,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    // Extract unique set of emails from selected papers
    const uniqueEmails = [
      ...new Set(selectedPapers.flatMap((paper) => paper.emails)),
    ];
    setSelectedEmails(uniqueEmails);
  }, [selectedPapers]);

  console.log(selectedEmails);

  const handleSendEmail = async () => {
    if (!selectedTemplate) {
      toast.error("Select a template before sending the email", {
        duration: 3000,
      });
      return;
    }

    try {
      const response = await fetch(`${base}/api/v1/auth/send/mail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        body: JSON.stringify({
          to: formData.to,
          subject: formData.subject,
          message: formData.message,
        }),
      });

      if (response.ok) {
        toast.success("Email sent successfully!", { duration: 3000 });
      } else {
        const errorData = await response.json();
        toast.error(`Failed to send email: ${errorData.message}`, {
          duration: 3000,
        });
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Failed to send email. Please try again.", {
        duration: 3000,
      });
    }
  };

  return (
    <div>
      <WelcomeChair id={id} />
      <div className="section-header text-center justify-content-center pt-4 mt-4">
        <h2>Send Emails</h2>
        <p>Here you can send the emails</p>
      </div>
      <div className="container vh-100 my-4 py-4">
        <div className="row">
          <div className="col-md-3">
            <div className="list-group">
              {emailTemplates.map((template) => (
                <button
                  key={template.id}
                  type="button"
                  className={`list-group-item list-group-item-action ${
                    selectedTemplate && selectedTemplate.id === template.id
                      ? "active-email"
                      : ""
                  }`}
                  onClick={() => handleTemplateSelection(template)}
                >
                  {template.name}
                </button>
              ))}
            </div>
          </div>
          <div className="col-md-9">
            <div className="card">
              <div className="card-body">
                {selectedTemplate ? (
                  <>
                    <form>
                      <div className="mb-3">
                        <label className="form-label">To:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="to"
                          value={formData.to}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Subject:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="subject"
                          value={formData.subject}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Message:</label>
                        <textarea
                          className="form-control"
                          rows={3}
                          name="message"
                          value={formData.message}
                          onChange={handleInputChange}
                        ></textarea>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Attachment:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="attachment"
                          value={formData.attachment}
                          onChange={handleInputChange}
                        />
                      </div>
                      <button
                        onClick={handleSendEmail}
                        type="button"
                        className="btn btn-custom1"
                      >
                        Send Email
                      </button>
                    </form>
                  </>
                ) : (
                  <p>Select a template to view details</p>
                )}
              </div>

              <div className="paperID">
                <p className="box d-flex flex-row justify-content-center flex-wrap">
                  {papers.map((paper, index) => (
                    <label
                      key={index}
                      className={`paper col-4 col-md-4 col-lg-3 mx-1 mt-3 bg-border-custom ${
                        selectedPapers.some(
                          (selectedPaper) => selectedPaper.title === paper.title
                        )
                          ? "disabled-paper"
                          : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        name={paper.title}
                        value={paper.title}
                        checked={selectedPapers.some(
                          (selectedPaper) => selectedPaper.title === paper.title
                        )}
                        onChange={() => handlePaperSelection(paper)}
                        disabled={selectedPapers.some(
                          (selectedPaper) => selectedPaper.title === paper.title
                        )}
                      />
                      <span style={{ cursor: "pointer" }}>
                        <h4 className="paper-title pt-2">{paper.title}</h4>
                      </span>
                    </label>
                  ))}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Email;
