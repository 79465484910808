import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteData, fetchData, sendData } from "./http";

export const useFetchQuery = (url, key) => {
    const { data, isSuccess, error } = useQuery({
        queryKey: [key],
        queryFn: () => fetchData(url),
    });
    return { data, isSuccess, error }
}

export const useMutateQuery = (url, onSuccessCallback, onErrorCallback, customHeaders) => {
    const { mutate, isPending, isError, error } = useMutation({
        mutationFn: (formData) => sendData(url, formData, customHeaders),
        onSuccess: (data) => {
            onSuccessCallback(data)
        },
        onError: (data) => {
            onErrorCallback(data)
        },
    });

    return { mutate, isPending, isError, error }
}

export const useDeleteMutateQuery = (url, onSuccessCallback, onErrorCallback) => {
    const { mutate, isPending, isError, error } = useMutation({
        mutationFn: () => deleteData(url),
        onSuccess: (data) => {
            onSuccessCallback(data)
        },
        onError: (data) => {
            onErrorCallback(data)
        },
    });

    return { mutate, isPending, isError, error }
}
