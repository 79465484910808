import Login from "./Components/Login";
import Register from "./Components/Register";
import { Navigate, Routes, Route } from "react-router-dom";
import Submission from "./Components/Submission";
import Create from "./Components/Create";
import CreateConf from "./Components/CreateConf";
import ForgetPassword from "./Components/ForgetPassword";
import ResetPassword from "./Components/ResetPassword";
import ConfrencesTab from "./Components/ConfrencesTab";
import RecentRoles from "./Components/RecentRoles";
import OrganizerView from "./chair/OrganizerView";
import ManageConference from "./chair/ManageConference";
import Information from "./chair/Information";
import SubmissionViewMore from "./Components/SubmissionViewMore";
import MyAccount from "./Components/MyAccount";
import Assignment from "./chair/Assignment";
import AddPC from "./chair/AddPC";
import ViewPC from "./chair/ViewPC";
import AgendaBuilder from "./chair/AgendaBuilder";
import PaperAssignment from "./chair/PaperAssignment";
import ViewPaper from "./reviewers/ViewPaper";
import ReviewPaper from "./reviewers/ReviewPaper";
import InformationPaper from "./reviewers/InformationPaper";
import CreateAgenda from "./chair/CreateAgenda";
import AddReviewer from "./chair/AddReviewer";
import Paperwise from "./chair/Paperwise";
import Reviewerwise from "./chair/Reviewerwise";
import Decision from "./chair/Decision";
import { getToken } from "./utils/LocalStorage";
import PaperwiseReport from "./chair/PaperwiseReport";
import ReviewerwiseReport from "./chair/ReviewerwiseReport";
import InformationReviewer from "./chair/InformationReviewer";
import Email from "./chair/Email";
import MyAccountEdit from "./Components/MyAccountEdit";
import Verify from "./Components/Verify";
import Decision1 from "./chair/Decision1";
import MyConference from "./chair/MyConference";
import EditConference from "./chair/EditConference";
import SubmissionEdit from "./Components/SubmissionEdit";

function App() {
  const token = getToken();

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={!token ? <Login /> : <Navigate to="/welcome" />}
        />
        <Route
          path="/register"
          element={!token ? <Register /> : <Navigate to="/welcome" />}
        />
        <Route
          path="/forget-password"
          element={!token ? <ForgetPassword /> : <Navigate to="/welcome" />}
        />
        <Route path="/password/reset/:id/:token" element={<ResetPassword />} />

        <Route exact path="/verify" element={<Verify />} />

        <Route path="/welcome" element={token ? <ConfrencesTab /> : <Navigate to="/" />} />

        <Route path="/submission/:id" element={<Submission />} />
        <Route path="/submission-view/:id" element={<SubmissionViewMore />} />
        <Route path="/submission-view-edit/:id" element={<SubmissionEdit />} />
        <Route path="/create/:id" element={<Create />} />
        <Route path="/create-conference" element={<CreateConf />} />
        <Route path="/organizer-view" element={<OrganizerView />} />
        <Route path="/recent-roles" element={<RecentRoles />} />
        <Route path="/manage-conference/:id" element={<ManageConference />} />
        {/* <Route path="/information/:id" element={<Information />} /> */}
        <Route path="/information/:id1/:id" element={<Information />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/my-account-edit" element={<MyAccountEdit />} />
        <Route path="/my-conference" element={<MyConference />} />
        <Route path="/my-conference-edit/:id" element={<EditConference />} />
        <Route path="/assignment/:id" element={<Assignment />} />
        <Route path="/add-reviewer/:id" element={<AddReviewer />} />
        <Route path="/add-chair/:id" element={<AddPC />} />
        <Route path="/view-pc/:id" element={<ViewPC />} />
        <Route path="/decision/:id" element={<Decision />} />
        <Route path="/decision1/:id" element={<Decision1 />} />
        <Route path="/paperwise/:id" element={<Paperwise />} />
        <Route path="/reviewerwise/:id" element={<Reviewerwise />} />
        <Route path="/email/:id" element={<Email />} />
        <Route path="/report-paper/:id" element={<PaperwiseReport />} />
        <Route path="/report-reviewer/:id" element={<ReviewerwiseReport />} />
        <Route
          path="/information-reviewer/:id"
          element={<InformationReviewer />}
        />
        <Route path="/agenda-builder" element={<AgendaBuilder />} />
        <Route path="/create-agenda" element={<CreateAgenda />} />
        <Route path="/paper-assignment/:id" element={<PaperAssignment />} />
        <Route path="/view-paper/:id" element={<ViewPaper />} />
        <Route path="/information-paper/:id" element={<InformationPaper />} />
        <Route path="/review-paper/:id" element={<ReviewPaper />} />
        {/* <Route path="*" element={<Soon />} /> */}
      </Routes>
    </div>
  );
}

export default App;
