import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getToken } from "../utils/LocalStorage";
import base from "../utils/base";
import { useFetchQuery, useMutateQuery } from "../utils/fetchData";
import toast from "react-hot-toast";

const AcceptedConferences = () => {
  const [conferences, setConferences] = useState([]);
  const [conferenceStatus, setConferenceStatus] = useState([]);
  const [approvedConferences, setApprovedConferences] = useState(0);
  const [acceptSubmissions, setAcceptSubmissions] = useState(false); // Ensure this is initialized to false

  const url = `${base}/api/v1/conference/all/user`

  const { data, isSuccess, error } = useFetchQuery(url, 'AcceptedConference')

  useEffect(() => {
    if (isSuccess && data) {
      setConferences(data.message);
      setConferenceStatus(data.status);
    }
  }, [isSuccess, data, conferences, conferenceStatus]);
  


  useEffect(() => {
    if (error) {
      setConferenceStatus(error.response.data.status);
    }
  }, [])

  useEffect(() => {
    if (conferenceStatus === "success") {
      Array.isArray(conferences) && conferences.map((data, index) => {
        if (data.status === "approve") {
          setApprovedConferences(index + 1);
        }
      });
    }
  }, [conferences, conferenceStatus]);


  const updateSubmission = async (id, currentIndex) => {
    try {
      const response = await fetch(`${base}/api/v1/conference/update/submission/${id}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: getToken(),
        },
        body: JSON.stringify({
          acceptSubmissions: !conferences[currentIndex].acceptSubmissions,
        }),
      });

      const data = await response.json();
      console.log(data);


      const updatedConferences = [...conferences];
      updatedConferences[currentIndex].acceptSubmissions = !conferences[currentIndex].acceptSubmissions;
      setConferences(updatedConferences);
      setAcceptSubmissions(!conferences[currentIndex].acceptSubmissions); // Update the acceptSubmissions state
    } catch (error) {
      console.error(error);
    }
  };



  return (
    <div>
      <div
        className="table-responsive table-scroll"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "700px" }}
      >
        {conferenceStatus === "failed" || approvedConferences === 0 ? (
          <>
            <table className="table table-striped mb-0">
              <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
                <tr>
                  <th scope="col" className="col-md-4">
                    Conference Name
                  </th>
                  <th scope="col" className="col-md-1">
                    Start Date
                  </th>
                  <th scope="col" className="col-md-1">
                    End Date
                  </th>
                  <th scope="col" className="col-md-2">
                    Country
                  </th>
                  <th scope="col" className="col-md-4">
                    External URL
                  </th>
                  <th scope="col" className="col-md-4">
                    Actions
                  </th>
                </tr>
              </thead>
            </table>
            <h3 className="text-center my-2">No Conference found</h3>
          </>
        ) : (
          <table className="table table-striped mb-0">
            <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
              <tr>
                <th scope="col" className="col-md-3">
                  Conference Name
                </th>
                <th scope="col" className="col-md-1">
                  Start Date
                </th>
                <th scope="col" className="col-md-1">
                  End Date
                </th>
                <th scope="col" className="col-md-1">
                  Country
                </th>
                <th scope="col" className="col-md-3">
                  External URL
                </th>
                <th scope="col" className="col-md-2">
                  Submission Status
                </th>
                <th scope="col" className="col-md-1">
                  Actions
                </th>
              </tr>
            </thead>
            {Array.isArray(conferences) && conferences.map((data, index) => {
              return (
                <tbody key={index}>
                  {data.status === "approve" ? (
                    <tr className="schedule-item">
                      <td>{data.conferenceTitle}</td>
                      <td>{data.startDate}</td>
                      <td>{data.endDate}</td>
                      <td>{data.country}</td>
                      <td>
                        <a
                          href="https://www.climatechange.ai/events/neurips2022"
                          className="table-url"
                        >
                          {data.webPage}
                        </a>
                      </td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={data.acceptSubmissions === true}
                            onChange={() => updateSubmission(data._id, index)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>
                      <td>
                        <Link to={`/manage-conference/${data._id}`}>
                          <button
                            className="btn btn-block"
                            style={{
                              backgroundColor: "#0e1b4d",
                              color: "#fff",
                            }}
                          >
                            Manage
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
              );
            })}
          </table>
        )}
      </div>
    </div>
  );
};

export default AcceptedConferences;
