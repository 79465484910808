import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import HeaderBackend from "./HeaderBackend";
import bg from "../welcome-bg.jpg";
import {
  getToken,
  storeItem,
  getItem,
  removeItem,
} from "../utils/LocalStorage";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchQuery, useMutateQuery } from "../utils/fetchData";
import base from "../utils/base";
import Spinner from "./Spinner";
import toast from "react-hot-toast";

const Create = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [user, setUser] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const [emails, setEmails] = useState([]);

  const [emailsArray, setEmailsArray] = useState([]);

  const handleAddEmail = () => {
    // removeItem('emails')
    // const storedEmails = getItem('emails');
    // console.log(storedEmails);

    // if (storedEmails) {
    if (emails.includes(",")) {
      const emailArray = emails.split(",").map((email) => email.trim());
      setEmailsArray((prevEmails) => [...prevEmails, ...emailArray]);

      const updatedEmailsArray = [...emailsArray, ...emailArray];
      storeItem("emails", JSON.stringify(updatedEmailsArray));
    } else {
      setEmailsArray((prevEmails) => [...prevEmails, emails.trim()]);
      const updatedEmailsArray = [...emailsArray, emails.trim()];
      storeItem("emails", JSON.stringify(updatedEmailsArray));
    }
    // }
  };

  const url1 = `${base}/api/v1/submission/create/${id}`;

  const handleSuccess = (data) => {
    toast.success(data.message, { duration: 3000 });
    navigate(`/submission/${id}`);
  };

  const handleError = (submitError) => {
    toast.error(submitError.response.data.message, { duration: 3000 });
  };

  const headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    Authorization: getToken(),
  };

  const {
    mutate,
    isPending,
    isError,
    error: submitError,
  } = useMutateQuery(url1, handleSuccess, handleError, headers);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("title", e.target.title.value);
    formData.append("abstract", e.target.abstract.value);
    formData.append("primaryArea", "Primary Area");
    formData.append("secondaryArea", "Secondary Area");
    for (let i = 0; i < emailsArray.length; i++) {
      formData.append("authors", emailsArray[i]);
    }
    formData.append("pdfFile", selectedFile);

    console.log(formData);

    await mutate(formData);
    removeItem("emails");
  };

  // Organizer Details
  const url = `${base}/api/v1/auth/get/me`;

  const { data, isSuccess, error } = useFetchQuery(url, "User");

  useEffect(() => {
    console.log(data);
    if (isSuccess && data) {
      setUser(data.message);
    }
  }, [isSuccess, data, user]);

  if (error) {
    toast.error(error.message, { duration: 3000 });
  }

  return (
    <div>
      <section
        id="welcome"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <HeaderBackend />
        <div
          className="container position-relative pt-5 text-center"
          data-aos="fade-up"
        >
          <h1>Create New Submission</h1>
          <h3>Author Console</h3>
        </div>
      </section>
      <div className="background-radial-gradient">
        <section id="schedule" className="bg-glass">
          <div className="container" data-aos="fade-up">
            <div className="col-lg-16 mb-5 mb-lg-0 position-relative">
              <div className="card bg-glass">
                <div className="card-body px-4 py-5 px-md-5">
                  <form onSubmit={handleSubmit}>
                    <div className="bg-custom bg-gradient text-dark p-5 mb-4 border-custom">
                      <div className="form-outline mb-4">
                        <h5>Title & Abstract</h5>
                        <hr />
                        <label className="form-label required">Title</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          required
                        />
                      </div>

                      <div className="form-outline mb-4">
                        <label className="form-label required">Abstract</label>
                        <textarea
                          type="text"
                          name="abstract"
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="bg-custom bg-gradient text-dark p-5 mb-4 border-custom">
                      <div className="form-outline mb-4">
                        <h5>Authors</h5>
                        <p>You may add your collabrators</p>
                        <hr />
                        <div className="row">
                          <div className="col-md-4 mb-4">
                            <div className="form-outline">
                              <input
                                type="text"
                                name="emails"
                                value={emails}
                                onChange={(e) => setEmails(e.target.value)}
                                placeholder="Enter email to add collabrator"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-md-4 mb-4">
                            <div className="form-outline">
                              <div
                                className="btn btn-secondary"
                                onClick={handleAddEmail}
                              >
                                Add
                              </div>
                            </div>
                          </div>
                        </div>
                        <table className="table">
                          <caption>List of collabrators</caption>
                          <thead>
                            <tr>
                              <th scope="col">Email</th>
                              <th scope="col">First Name</th>
                              <th scope="col">Last Name</th>
                              <th scope="col">Organization</th>
                              <th scope="col">Country/Region</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">{user && user.email}</th>
                              <td>{user && user.firstName}</td>
                              <td>{user && user.lastName}</td>
                              <td>{user && user.organizationName}</td>
                              <td>{user && user.country}</td>
                            </tr>
                            {emailsArray.map((email, index) => {
                              const collaboratorDetails = getItem("emails");

                              return (
                                <tr key={index}>
                                  <th scope="row">{email}</th>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="bg-custom bg-gradient text-dark p-5 mb-4 border-custom">
                      <div className="form-outline">
                        <h5>Subject Areas</h5>
                        <p>You may select up to 3 subject areas.</p>
                        <hr />
                      </div>
                      <div className="row">
                        <div className="col-md-4 mb-4">
                          <label>
                            <h6 className="form-label">1st Domain</h6>
                          </label>
                          <div className="form-outline">
                            <input
                              type="checkbox"
                              name="domain"
                              className=""
                              id="1"
                            />
                            <label htmlFor="1">
                              Artificial Intelligence, Machine Learning for
                              Pattern Analysis
                            </label>
                            <br />
                            <input
                              type="checkbox"
                              name="domain"
                              className=""
                              id="1"
                            />
                            <label htmlFor="1">
                              Artificial Intelligence, Machine Learning for
                              Pattern Analysis
                            </label>
                            <br />
                            <input
                              type="checkbox"
                              name="domain"
                              className=""
                              id="1"
                            />
                            <label htmlFor="1">
                              Artificial Intelligence, Machine Learning for
                              Pattern Analysis
                            </label>
                            <br />
                          </div>
                        </div>
                        <div className="col-md-4 mb-4">
                          <label>
                            <h6 className="form-label">2nd Domain</h6>
                          </label>
                          <div className="form-outline">
                            <input
                              type="checkbox"
                              name="domain"
                              className=""
                              id="1"
                            />
                            <label htmlFor="1">
                              Artificial Intelligence, Machine Learning for
                              Pattern Analysis
                            </label>
                            <br />
                            <input
                              type="checkbox"
                              name="domain"
                              className=""
                              id="1"
                            />
                            <label htmlFor="1">
                              Artificial Intelligence, Machine Learning for
                              Pattern Analysis
                            </label>
                            <br />
                            <input
                              type="checkbox"
                              name="domain"
                              className=""
                              id="1"
                            />
                            <label htmlFor="1">
                              Artificial Intelligence, Machine Learning for
                              Pattern Analysis
                            </label>
                            <br />
                          </div>
                        </div>
                        <div className="col-md-4 mb-4">
                          <label>
                            <h6 className="form-label">3rd Domain</h6>
                          </label>
                          <div className="form-outline">
                            <input
                              type="checkbox"
                              name="domain"
                              className=""
                              id="1"
                            />
                            <label htmlFor="1">
                              Artificial Intelligence, Machine Learning for
                              Pattern Analysis
                            </label>
                            <br />
                            <input
                              type="checkbox"
                              name="domain"
                              className=""
                              id="1"
                            />
                            <label htmlFor="1">
                              Artificial Intelligence, Machine Learning for
                              Pattern Analysis
                            </label>
                            <br />
                            <input
                              type="checkbox"
                              name="domain"
                              className=""
                              id="1"
                            />
                            <label htmlFor="1">
                              Artificial Intelligence, Machine Learning for
                              Pattern Analysis
                            </label>
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-custom bg-gradient text-dark p-5 mb-4 border-custom">
                      <div className="form-outline mb-4">
                        <h5>Files</h5>
                        <p>
                          You can upload your paper. Maximum file size is 5 Mb.
                          We only accept pdf formats.
                        </p>
                        <hr />
                        <label className="form-label required">
                          Upload your files
                        </label>
                        <input
                          type="file"
                          onChange={handleFileChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>

                    <button
                      className="btn btn-primary btn-block mb-2"
                      type="submit"
                    >
                      {isPending ? <Spinner /> : "Submit"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Create;
