import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../Components/Footer";
import WelcomeChair from "./WelcomeChair";
import { getToken } from "../utils/LocalStorage";
import base from "../utils/base";
import { useFetchQuery, useMutateQuery } from "../utils/fetchData";
import toast from "react-hot-toast";

const ManageConference = () => {

  const navigate = useNavigate()

  const [submissions, setSubmissions] = useState([]);
  const [submissionsStatus, setSubmissionsStatus] = useState([]);
  const [userMapping, setUserMapping] = useState({});

  const { id } = useParams();


  const url = `${base}/api/v1/submission/getby/conference/${id}`

  const { data, isSuccess, error } = useFetchQuery(url, 'SingleConference')

  useEffect(() => {
    if (isSuccess && data) {
      setSubmissions(data.message);
      setSubmissionsStatus(data.status);
    }
  }, [isSuccess, data, submissions, submissionsStatus]);

  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }


  const fetchOrganizerDetails = (userId) => {
    const organizerHost = `${base}/api/v1/auth/get/id/${userId}`;
    fetch(organizerHost, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: getToken(),
      },
    }).then(async (res) => {
      const userData = await res.json();
      setUserMapping((prevMapping) => ({
        ...prevMapping,
        [userId]: userData.message.firstName,
      }));
    });
  };

  React.useEffect(() => {
    if (submissionsStatus === "success") {
      submissions.forEach((submission) => {
        for (let i = 0; i < submission.authors.length; i++) {
          const element = submission.authors[i];
          fetchOrganizerDetails(element);
        }
        fetchOrganizerDetails(submission.author);
      });
    }
  }, [submissions, submissionsStatus]);



  const [conference, setConference] = useState([]);
  const url1 = `${base}/api/v1/conference/${id}`

  const { data: conferenceData, isSuccess: conferenceSuccess, error: conferenceError } = useFetchQuery(url1, 'ConferenceByID')

  useEffect(() => {
    if (conferenceSuccess && conferenceData) {
      setConference(conferenceData.message);
    }
  }, [conferenceSuccess, conferenceData, conference]);

  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }


  const url2 = `${base}/api/v1/conference/update/submission/${id}`

  const handleSuccess = (data) => {
    toast.success(data.message, { duration: 3000 });
    navigate(`/manage-conference/${id}`)
  };

  const handleError = (data) => {
    toast.error(data.response.data.message, { duration: 3000 });
  };

  const { mutate, isPending, isError, error: updateError } = useMutateQuery(url2, handleSuccess, handleError)


  const updateSubmission = async () => {

    const body = {
        acceptSubmissions: !conference.acceptSubmissions,
    }

    await mutate(body);
  };


  return (
    <div>
      <WelcomeChair id={id} />
      <div className="section-header text-center justify-content-center pt-4 mt-4">
        <div className="d-flex flex-row text-center justify-content-center">
          <h2>Submissions</h2>
          <div className="px-3">
            <label className="switch">
              <input
                type="checkbox"
                checked={conference.acceptSubmissions === true}
                onChange={() => updateSubmission()}
              />

              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <p>Here is the list of all submissions</p>
      </div>
      <section className="vh-100">
        <div
          className="table-responsive table-scroll px-2 mx-2 pt-2 mt-4"
          data-mdb-perfect-scrollbar="true"
        >
          {submissionsStatus === "failed" ? (
            <>
              <table className="table table-striped mb-0 table-bordered">
                <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
                  <tr>
                    <th scope="col" className="col-md-1">
                      Paper ID
                    </th>
                    <th scope="col" className="col-md-4">
                      Authors
                    </th>
                    <th scope="col" className="col-md-3">
                      Title
                    </th>
                    <th scope="col" className="col-md-0.5 writing-mode">
                      Information
                    </th>
                    <th scope="col" className="col-md-0.5 writing-mode">
                      Paper
                    </th>
                    <th scope="col" className="col-md-0.5 writing-mode">
                      Assignment
                    </th>
                    <th scope="col" className="col-md-0.5 writing-mode">
                      Decision
                    </th>
                    <th scope="col" className="col-md-2">
                      Time
                    </th>
                  </tr>
                </thead>
              </table>
              <h3 className="text-center my-2">No Submissions Found</h3>
            </>
          ) : (
            <table className="table table-striped mb-0 table-bordered">
              <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
                <tr>
                  <th scope="col" className="col-md-1">
                    Paper ID
                  </th>
                  <th scope="col" className="col-md-4">
                    Authors
                  </th>
                  <th scope="col" className="col-md-3">
                    Title
                  </th>
                  <th scope="col" className="col-md-0.5 writing-mode">
                    Information
                  </th>
                  <th scope="col" className="col-md-0.5 writing-mode">
                    Paper
                  </th>
                  <th scope="col" className="col-md-0.5 writing-mode">
                    Assignment
                  </th>
                  <th scope="col" className="col-md-0.5 writing-mode">
                    Decision
                  </th>
                  <th scope="col" className="col-md-2">
                    Time
                  </th>
                </tr>
              </thead>
              {submissions.map((data, index) => {
                return (
                  <tbody key={index}>
                    <tr className="schedule-item">
                      <td>{data.paperId}</td>
                      <td>
                        {userMapping[data.author]},
                        {data.authors.map((element, index) => (
                          <React.Fragment key={index}>
                            {userMapping[element]}
                            {index < data.authors.length - 1 ? ", " : ""}
                          </React.Fragment>
                        )) || "Loading..."}
                      </td>
                      <td>{data.title}</td>
                      {/* <td>
                      <Link to={`/submission-view/${data._id}`}>
                        <img
                          width="20"
                          height="20"
                          src="https://img.icons8.com/ios-filled/50/info.png"
                          alt="info"
                        />
                      </Link>
                    </td> */}
                      <td>
                        <Link to={`/information/${id}/${data._id}`}>
                          <img
                            width="20"
                            height="20"
                            src="https://img.icons8.com/ios-filled/50/info.png"
                            alt="info"
                          />
                        </Link>
                      </td>
                      <td>
                        <a href={`${data.file}`} className="table-url">
                          <img
                            width="24"
                            height="24"
                            src="https://img.icons8.com/plasticine/100/folder-invoices.png"
                            alt="folder-invoices"
                          />
                        </a>
                      </td>
                      <td>
                        <Link to={`/assignment/${data._id}`}>
                          <img
                            width="24"
                            height="24"
                            src="https://img.icons8.com/color/48/available-updates.png"
                            alt="available-updates"
                          />
                        </Link>
                      </td>
                      <td>
                        <Link to={`/paper-assignment/${data._id}`}>
                          <img
                            width="24"
                            height="24"
                            src="https://img.icons8.com/color/48/fine-print.png"
                            alt="available-updates"
                          />
                        </Link>
                      </td>
                      <td>{data.createdAt}</td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ManageConference;
