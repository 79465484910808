import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import bg from "../hero-bg.jpg";
import Header from "../home/Header";
import Footer from "./Footer";
import Spinner from "./Spinner";
import { storeToken } from "../utils/LocalStorage";
import HeaderLogin from "../home/HeaderLogin";
import base from "../utils/base";
import toast from 'react-hot-toast';
import { useMutateQuery } from "../utils/fetchData";

const Login = () => {

  const url = `${base}/api/v1/auth/login`

  const handleSuccess = (data) => {
    storeToken(data.token, 432000);
    toast.success(data.message, { duration: 3000 });
    setTimeout(() => {
      window.location.href = '/welcome'
    }, 3000);
  };

  const handleError = (data) => {
    toast.error(data.response.data.message, { duration: 3000 });
  };

  const { mutate, isPending, isError, error } = useMutateQuery(url, handleSuccess, handleError)


  const handleLogin = async (e) => {
    e.preventDefault();

    const formData = {
      email: e.target.email.value,
      password: e.target.password.value,
    };

    await mutate(formData);
  };


  return (
    <div>
      <div
        className="vh-100"
        style={{ backgroundImage: `url(${bg})` }}
        id="hero1"
      >
        <HeaderLogin />
        <section className="pt-5 mt-3">
          <div className="container h-25">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                <div
                  className="card shadow-2-strong"
                  style={{ borderRadius: "1rem" }}
                >
                  <form onSubmit={handleLogin} className="card-body p-5">
                    <h3 className="mb-3 text-center">Login to ConfChair</h3>
                    <div className="form-outline mb-4">
                      <label className="form-label required">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="typeEmailX-2"
                        className="form-control form-control-lg"
                      />
                    </div>

                    <div className="form-outline mb-4">
                      <label className="form-label required">
                        Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="typePasswordX-2"
                        className="form-control form-control-lg"
                      />
                    </div>

                    <div className="text-center text-lg-start mt-2">
                      <div className="mb-2">
                        <button
                          type="sumbit"
                          className="btn bg-custom-red text-white btn-lg btn-hover"
                          style={{
                            paddingLeft: "2.5rem",
                            paddingRight: "2.5rem",
                          }}
                        >
                          {isPending ? <Spinner /> : "Login"}
                        </button>
                      </div>
                      <div className="mb-2">
                        <p>
                          Don't have an account?{" "}
                          <a href="/register" className="a-link">
                            Register Now
                          </a>
                        </p>
                      </div>
                      <div className="mb-2">
                        <a href="/forget-password" className="a-link">
                          Forgot Password ?
                        </a>
                      </div>
                    </div>

                    {/* <hr className="my-4" />

                    <button
                      className="btn btn-lg btn-block btn-primary"
                      style={{ backgroundColor: "#dd4b39" }}
                      type="submit"
                    >
                      <i className="fab fa-google me-2"></i> Sign in with google
                    </button>
                    <button
                      className="btn btn-lg btn-block btn-primary mb-2"
                      style={{ backgroundColor: "#3b5998" }}
                      type="submit"
                    >
                      <i className="fab fa-facebook-f me-2"></i>Sign in with
                      facebook
                    </button> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
