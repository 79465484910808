import React, { useEffect, useState } from "react";
import WelcomeChair from "./WelcomeChair";
import Footer from "../Components/Footer";
import { useParams } from "react-router-dom";
import { useFetchQuery } from "../utils/fetchData";
import base from "../utils/base";
import toast from "react-hot-toast";

const PaperAssignment = () => {
  const [papers, setPapers] = useState([]);

  const [paperStatus, setPaperStatus] = useState("");


  const { id } = useParams();

  const url = `${base}/api/v1/submission/get/${id}`

  const { data, isSuccess, error } = useFetchQuery(url, 'SubmissionID')

  useEffect(() => {
    if (isSuccess && data) {
      setPapers(data.message);
      setPaperStatus(data.status);
    }
  }, [isSuccess, data, papers, paperStatus]);

  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }

  return (
    <div>
      <WelcomeChair />
      <div className="section-header text-center justify-content-center pt-4 mt-4">
        <h2>Decision</h2>
        <p>Here you can see the panel</p>
      </div>
      <div
        className="table-responsive table-scroll px-4 mx-4"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "200px" }}
      >
        {paperStatus === "failed" ? (
          <>
            <table className="table table-striped mb-0 table-bordered">
              <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
                <tr>
                  <th scope="col" className="col-md-2">
                    Name
                  </th>
                  <th scope="col" className="col-md-2">
                    Comment
                  </th>
                  <th scope="col" className="col-md-2">
                    Ratings
                  </th>
                </tr>
              </thead>
            </table>
            <h3 className="text-center my-2">No Conferences</h3>
          </>
        ) : (
          <table className="table table-striped mb-0 table-bordered">
            <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
              <tr>
                <th scope="col" className="col-md-2">
                  Name
                </th>
                <th scope="col" className="col-md-2">
                  Comment
                </th>
                <th scope="col" className="col-md-2">
                  Ratings
                </th>
              </tr>
            </thead>
            {/* {papers.map((data, index) => {

              return <tbody key={index}>
                <tr className="schedule-item">
                  <td>{data.author}</td>
                  <td>{data.abstract}</td>
                  <td>According to the format</td>
                  <td>+2</td>
                  <td>Accepted</td>
                </tr>
              </tbody>
            })
            } */}

          </table>
        )}
      </div>
      <div>
        Send Email
      </div>
      <p className="box d-flex flex-row justify-content-center flex-wrap text-center">
        <label className="paper col-4 col-md-4 col-lg-1 mx-1 mt-3 bg-border-custom">
          <input type="radio" name="Sanjay" value="1" />
          <span>
            <h4 className="paper-title pt-2">Accept</h4>
          </span>
        </label>
        <label className="paper col-4 col-md-4 col-lg-1 mx-1 mt-3 bg-border-custom">
          <input type="radio" name="Sanjay" value="1" />
          <span>
            <h4 className="paper-title pt-2">Reject</h4>
          </span>
        </label>
        <label className="paper col-4 col-md-4 col-lg-1 mx-1 mt-3 bg-border-custom">
          <input type="radio" name="Sanjay" value="1" />
          <span>
            <h4 className="paper-title pt-2">Update</h4>
          </span>
        </label>
      </p>
      <Footer />
    </div>
  );
};

export default PaperAssignment;
