import React, { useRef } from "react";
import WelcomeChair from "./WelcomeChair";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const AgendaBuilder = () => {
  const pdfRef = useRef();
  const handleExport = () => {
    const input = pdfRef.current;
    html2canvas(input, { useCORS: true, scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true); // 'p' for portrait, 'a4' for A4 size
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 10;
      pdf.addImage(
        imgData,
        "JPEG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("Inauguration by Confchair.pdf");
    });
  };

  return (
    <div>
      <WelcomeChair />
      <div className="container" ref={pdfRef}>
        <div className="d-flex flex-row text-center justify-content-center">
          <div className="col-4">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/2/21/IEEE_logo.svg"
              className=""
              alt="Logo 1"
            />
          </div>
          <div className="col-4">
            <h2 className="pt-4">
              International Conference on Advanced Computing & Systems
            </h2>
          </div>
          <div className="col-4">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/2/21/IEEE_logo.svg"
              className=""
              alt="Logo 2"
            />
          </div>
        </div>
        <div className="text-center">
          <h3>(ICIICS-2023)</h3>
          <h2>24th & 25th November, 2023</h2>
          <h4>Organized By</h4>
          <h4>Sharnbasva University, Kalaburagi, Karnataka</h4>
          <h3>Inauguration</h3>
        </div>
        <div className="d-flex flex-row justify-content-center text-center">
          <div className="col-4">
            <h3>
              Platform: <span className="text-danger">WebEx</span>
            </h3>
          </div>
          <div className="col-4">
            <h3>
              Date: <span className="text-danger">24th November, 2023</span>
            </h3>
          </div>
          <div className="col-4">
            <h3>
              Time: <span className="text-danger">10:30 AM- 01.30 PM</span>
            </h3>
          </div>
        </div>
        <div className="justify-content-center text-center">
          <div
            className="table-responsive table-scroll"
            data-mdb-perfect-scrollbar="true"
            style={{ position: "relative" }}
          >
            <table className="table table-striped mb-0 table-bordered">
              <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
                <tr>
                  <th scope="col" className="col-md-2">
                    Time
                  </th>
                  <th scope="col" className="col-md-4">
                    Activity
                  </th>
                  <th scope="col" className="col-md-6">
                    Presenter
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="schedule-item">
                  <td>10:30 - 11:00</td>
                  <td>Welcome Address</td>
                  <td>
                    Dr. Soenil Soebedar, Founder & CEO, Soebit Cybersecurity
                    Title: Cybersecurity & Cryptography within Critical
                    Infrastructure
                  </td>
                </tr>
                <tr className="schedule-item">
                  <td>10:30 - 11:00</td>
                  <td>Welcome Address</td>
                  <td>
                    Dr. Soenil Soebedar, Founder & CEO, Soebit Cybersecurity
                    Title: Cybersecurity & Cryptography within Critical
                    Infrastructure
                  </td>
                </tr>
                <tr className="schedule-item">
                  <td>10:30 - 11:00</td>
                  <td>Welcome Address</td>
                  <td>
                    Dr. Soenil Soebedar, Founder & CEO, Soebit Cybersecurity
                    Title: Cybersecurity & Cryptography within Critical
                    Infrastructure
                  </td>
                </tr>
                <tr className="schedule-item">
                  <td>10:30 - 11:00</td>
                  <td>Welcome Address</td>
                  <td>
                    Dr. Soenil Soebedar, Founder & CEO, Soebit Cybersecurity
                    Title: Cybersecurity & Cryptography within Critical
                    Infrastructure
                  </td>
                </tr>
                <tr className="schedule-item">
                  <td>10:30 - 11:00</td>
                  <td>Welcome Address</td>
                  <td>
                    Dr. Soenil Soebedar, Founder & CEO, Soebit Cybersecurity
                    Title: Cybersecurity & Cryptography within Critical
                    Infrastructure
                  </td>
                </tr>
                <tr className="schedule-item">
                  <td>10:30 - 11:00</td>
                  <td>Welcome Address</td>
                  <td>
                    Dr. Soenil Soebedar, Founder & CEO, Soebit Cybersecurity
                    Title: Cybersecurity & Cryptography within Critical
                    Infrastructure
                  </td>
                </tr>
                <tr className="schedule-item">
                  <td>10:30 - 11:00</td>
                  <td>Welcome Address</td>
                  <td>
                    Dr. Soenil Soebedar, Founder & CEO, Soebit Cybersecurity
                    Title: Cybersecurity & Cryptography within Critical
                    Infrastructure
                  </td>
                </tr>
                <tr className="schedule-item">
                  <td>10:30 - 11:00</td>
                  <td>Welcome Address</td>
                  <td>
                    Dr. Soenil Soebedar, Founder & CEO, Soebit Cybersecurity
                    Title: Cybersecurity & Cryptography within Critical
                    Infrastructure
                  </td>
                </tr>
                <tr className="schedule-item">
                  <td>10:30 - 11:00</td>
                  <td>Welcome Address</td>
                  <td>
                    Dr. Soenil Soebedar, Founder & CEO, Soebit Cybersecurity
                    Title: Cybersecurity & Cryptography within Critical
                    Infrastructure
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <h4>
            <a href="" className="text-primart">
              Click Here
            </a>{" "}
            to join the Inauguration
          </h4>
          <p>Meeting number: 2535 756 6481</p>
        </div>
        <div className="text-center">
          <h3>
            Powered By{" "}
            <a href="https://confchair.com" className="text-danger">
              CONFCHAIR
            </a>
          </h3>
        </div>
      </div>
      <div className="text-center p-4 m-4">
        <button className="btn btn-primary" onClick={handleExport}>
          Export to PDF
        </button>
      </div>
    </div>
  );
};

export default AgendaBuilder;