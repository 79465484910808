import React, { useState, useEffect } from "react";
import { useFetchQuery } from "../utils/fetchData";
import base from "../utils/base";
import toast from "react-hot-toast";

const AllConferencesOrganizer = () => {
  const [conferences, setConferences] = useState([]);

  const [conferencesStatus, setConferencesStatus] = useState([]);

  const url = `${base}/api/v1/conference/all/user`

  
  const { data, isSuccess, error } = useFetchQuery(url, 'UserAcceptedConferences')
  

  useEffect(() => {
    if (isSuccess && data) {
      setConferences(data.message);
      setConferencesStatus(data.status);
    }
  }, [isSuccess, data, conferences, conferencesStatus]);

  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }


  return (
    <div>
      <div
        className="table-responsive table-scroll"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "700px" }}
      >
        {conferencesStatus === "failed" ? (
          <>
            <table className="table table-striped mb-0">
              <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
                <tr>
                  <th scope="col" className="col-md-4">
                    Conference Name
                  </th>
                  <th scope="col" className="col-md-1">
                    Start Date
                  </th>
                  <th scope="col" className="col-md-1">
                    End Date
                  </th>
                  <th scope="col" className="col-md-2">
                    Country
                  </th>
                  <th scope="col" className="col-md-4">
                    External URL
                  </th>
                </tr>
              </thead>
            </table>
            <h3 className="text-center my-2">No Conferences</h3>
          </>
        ) : (
          <table className="table table-striped mb-0">
            <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
              <tr>
                <th scope="col" className="col-md-4">
                  Conference Name
                </th>
                <th scope="col" className="col-md-1">
                  Start Date
                </th>
                <th scope="col" className="col-md-1">
                  End Date
                </th>
                <th scope="col" className="col-md-2">
                  Country
                </th>
                <th scope="col" className="col-md-4">
                  External URL
                </th>
              </tr>
            </thead>

            {Array.isArray(conferences) && conferences.length > 0 ? (
                <tbody>
                  {conferences.map((data, index) => (
                    <tr key={index} className="schedule-item">
                      <td>{data.conferenceTitle}</td>
                      <td>{data.startDate}</td>
                      <td>{data.endDate}</td>
                      <td>
                        {data.city}, {data.country}
                      </td>
                      <td>
                        <a href={`${data.webPage}`} className="table-url">
                          {data.webPage}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
            ) : (
              <h3 className="text-center my-2">No Conferences</h3>
            )}
          </table>
        )}
      </div>
    </div>
  );
};

export default AllConferencesOrganizer;
