import React from "react";
import WelcomeChair from "./WelcomeChair";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";

const MyConference = () => {
  return (
    <div>
      <WelcomeChair />
      <div className="section-header text-center justify-content-center pt-4 mt-4">
        <h2>My Conference</h2>
        <p>Here you can see details about your conference</p>
      </div>
      <h2 className="text-center pt-4 mt-4">Organizer</h2>
      <div
        className="table-responsive table-scroll px-5 mx-5"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "150px" }}
      >
        <table className="table table-striped mb-0">
          <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
            <tr>
              <th scope="col" className="col-md-2">
                First Name
              </th>
              <th scope="col" className="col-md-2">
                Last Name
              </th>
              <th scope="col" className="col-md-3">
                Email
              </th>
              <th scope="col" className="col-md-1">
                Affiliation
              </th>
              <th scope="col" className="col-md-3">
                Contact
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="schedule-item">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        className="information table-responsive table-scroll p-5 m-5 pb-0 mb-0"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "900px" }}
      >
        <div className="d-flex flex-row justify-content-between">
          <h2 className="text-center">Conference Name</h2>
          <Link to="/my-conference-edit">
            <div className="d-flex flex-row">
              <img
                width="20"
                height="20"
                className="d-flex flex-row justify-content-end"
                src="https://img.icons8.com/color/48/edit.png"
                alt="edit"
              />
              <p>Edit</p>
            </div>
          </Link>
        </div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Type</th>
              <td scope="row"></td>
            </tr>
            <tr>
              <th scope="col">Conference Title</th>
              <td scope="row"></td>
            </tr>
            <tr>
              <th scope="col">Acronym</th>
              <td scope="row"></td>
            </tr>
            <tr>
              <th scope="col">Web Page</th>
              <td scope="row"></td>
            </tr>
            <tr>
              <th scope="col">Mode Of Conference</th>
              <td scope="row"> </td>
            </tr>
            <tr>
              <th scope="col">Venue</th>
              <td scope="row"> </td>
            </tr>
            <tr>
              <th scope="col">City</th>
              <td scope="row"></td>
            </tr>
            <tr>
              <th scope="col">Country/Region</th>
              <td scope="row"> </td>
            </tr>
            <tr>
              <th scope="col">Estimated number of submissions</th>
              <td scope="row"> </td>
            </tr>
            <tr>
              <th scope="col">Start Date</th>
              <td scope="row"> </td>
            </tr>
            <tr>
              <th scope="col">End Date</th>
              <td scope="row"> </td>
            </tr>
            <tr>
              <th scope="col">Primary Area</th>
              <td scope="row"> </td>
            </tr>
            <tr>
              <th scope="col">Secondary Area</th>
              <td scope="row"> </td>
            </tr>
            <tr>
              <th scope="col">Area Notes</th>
              <td scope="row"> </td>
            </tr>
            <tr>
              <th scope="col">Any other Information</th>
              <td scope="row"> </td>
            </tr>
          </thead>
        </table>
      </div>
      <Footer />
    </div>
  );
};

export default MyConference;
