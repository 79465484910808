import React, { useState, useEffect } from "react";
import WelcomeChair from "./WelcomeChair";
import Footer from "../Components/Footer";
import { useParams } from "react-router-dom";
import { getToken } from "../utils/LocalStorage";
import base from "../utils/base";
import { useFetchQuery } from "../utils/fetchData";
import toast from "react-hot-toast";

const Reviewerwise = () => {

  const [papers, setPapers] = useState([]);
  const [papersStatus, setPapersStatus] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [reviewersStatus, setReviewersStatus] = useState([]);

  const { id } = useParams();

  const url = `${base}/api/v1/submission/getby/conference/${id}`

  const { data, isSuccess, error } = useFetchQuery(url, 'SubmissionUsingConference')

  useEffect(() => {
    if (isSuccess && data) {
      setPapers(data.message);
      setPapersStatus(data.status);
    }
  }, [isSuccess, data, papers, papersStatus]);

  if (error) {
    console.log(error);
    toast.error(error.response.data.message, { duration: 3000 });
  }
  
  const url2 = `${base}/api/v1/conference/view/reviewers/${id}`
  const { data:reviewerData, isSuccess:reviewerSuccess, error:reviewerError } = useFetchQuery(url2, 'ConferencveReviewer')
  useEffect(() => {
    if (reviewerSuccess && reviewerData) {
      setReviewers(reviewerData.message);
      setReviewersStatus(reviewerData.status);
    }
  }, [reviewerSuccess, reviewerData, papers, papersStatus]);

  if (reviewerError) {
    toast.error(reviewerError.response.data.message, { duration: 3000 });
  }



  const isPaperAssigned = (reviewerID, paperReviewers) => {
    return paperReviewers.some((paper) => paper.user === reviewerID)
  }

  const togglePaperAssignment = async (reviewer, paperReviewers, paperID) => {
    try {
      const isAssigned = isPaperAssigned(reviewer._id, paperReviewers);

      const host = `${base}/api/v1/submission/update/reviewers/${paperID}`;
      const method = "POST";

      const response = await fetch(host, {
        method,
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: getToken(),
        },
        body: JSON.stringify({
          reviewerEmails: [reviewer.email],
          operation: isAssigned ? 'remove' : 'add',
        }),
      });

      const result = await response.json();
      console.log(result);
      // window.location.reload()

      if (result.status === "success") {
        // Fetch the updated reviewers separately
        const updatedResponse = await fetch(`${base}/api/v1/submission/getby/conference/${id}`);
        const updatedData = await updatedResponse.json();

        setPapers(updatedData.message || []);
      } else {
        toast.error(result.message, {duration:3000})
      }
    } catch (error) {
      console.error("Error toggling paper assignment:", error);
    }
  };



  return (
    <div>
      <WelcomeChair id={id}/>
      <div className="section-header text-center justify-content-center pt-4 mt-4">
        <h2>Add Papers</h2>
        <p>Here you can add papers to reviewers</p>
      </div>
      {reviewers.map((reviewer, index) => {
        return (
          <div key={index} className="paperwise text-center mt-4 pt-4">
            <div className="text-center">
              <h3 className="bg-border-custom-title d-inline-block col-lg-2 col-md-4 col-6">
                {reviewer.firstName}
                <p style={{ fontSize: '1rem' }} className="mt-2">{reviewer.email}</p>
              </h3>
            </div>
            <div className="box d-flex flex-row justify-content-center flex-wrap">
              {papers.map((paper, paperIndex) => (
                <label key={paperIndex} className="paper col-4 col-md-4 col-lg-2 mx-1 mt-3 bg-border-custom">
                  <input
                    type="checkbox"
                    name="Sanjay"
                    checked={isPaperAssigned(reviewer._id, paper.reviewers)}
                    onChange={() => togglePaperAssignment(reviewer, paper.reviewers, paper._id)}
                  />
                  <span>
                    <p className="paper-title pt-2">{paper.title}</p>
                    {/* <div className="d-flex flex-row justify-content-center flex-wrap">
                      <p className="paper-desc m-0 px-1">Assigned-: 2,</p>
                    </div> */}
                  </span>
                </label>
              ))}
            </div>
            <hr className="col-11 mx-auto dark-hr mt-4" />
          </div>
        );
      })}
      <Footer />
    </div>
  );
};

export default Reviewerwise;
