import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import WelcomeReviewer from "./WelcomeReviewer";
import Footer from "../Components/Footer";
import Spinner from "../Components/Spinner";
import { useMutateQuery } from "../utils/fetchData";
import base from "../utils/base";
import toast from "react-hot-toast";

const ReviewPaper = () => {


  const { id } = useParams();

  const url = `${base}/api/v1/submission/add/cr/${id}`

  const handleSuccess = (data) => {
    toast.success(data.message, { duration: 3000 });
  };

  const handleError = (error) => {
    toast.error(error.response.data.message, { duration: 3000 });
  };

  const { mutate, isPending, isError, error } = useMutateQuery(url, handleSuccess, handleError)

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      comment: e.target.comment.value,
      rating: Number(e.target.rating.value),
      remark: e.target.remark.value,
    };

    await mutate(formData);
  };


  return (
    <div>
      <WelcomeReviewer />
      <div className="section-header text-center justify-content-center pt-4 mt-4">
        <h2>Review Paper</h2>
        <p>Add a comment and rating to the assigned paper</p>
      </div>
      <form onSubmit={handleSubmit}>
        <div
          className="information table-responsive table-scroll p-5 m-5 pb-0 mb-0"
          data-mdb-perfect-scrollbar="true"
          style={{ position: "relative", height: "700px" }}
        >
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" className="col-3">
                  Reviewer's Confidence
                </th>
                <td scope="row">
                  <select name="rating" className="form-select">
                    <option value="" className="">
                      Select
                    </option>
                    <option value="-2" className="">
                      Reject (-2)
                    </option>
                    <option value="-1" className="">
                      Need Imporvement (-1)
                    </option>
                    <option value="0" className="">
                      Weak Accept (0)
                    </option>
                    <option value="+1" className="">
                      Accept (+1)
                    </option>
                    <option value="+2" className="">
                      Excellent (+2)
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <th scope="col" className="col-3">
                  Overall Evaluation
                </th>
                <td scope="row">
                  <textarea
                    type="text"
                    className="form-control"
                    name="comment"
                    style={{ position: "relative", height: "200px" }}
                    required
                  />
                </td>
              </tr>

              <tr>
                <th scope="col" className="col-3">
                  Confidential remarks for the program committee
                </th>
                <td scope="row">
                  <textarea
                    type="text"
                    className="form-control"
                    name="remark"
                    style={{ position: "relative", height: "200px" }}
                    required
                  />
                </td>
              </tr>
            </thead>
          </table>
          <div className="text-center my-4 py-2">
            <button type="submit" className="btn btn-block btn-custom">{isPending ? <Spinner /> : 'Submit'}</button>
          </div>
        </div>
      </form>
      <Footer />
    </div>
  );
};

export default ReviewPaper;
