import React, { useState } from 'react'
import bg from "../hero-bg.jpg";
import { useNavigate, useParams } from "react-router-dom";
import base from '../utils/base';
import { useMutateQuery } from '../utils/fetchData';
import Spinner from './Spinner';
import toast from 'react-hot-toast';

const ResetPassword = () => {

  const { id, token } = useParams()

  const navigate = useNavigate();

  const url = `${base}/api/v1/auth/password/reset/${id}/${token}`

  const handleSuccess = (data) => {
    toast.success(data.message, { duration: 3000 });
    navigate('/')
  };

  const handleError = (error) => {
    toast.error(error.response.data.message, { duration: 3000 });
  };

  const { mutate, isPending, isError, error } = useMutateQuery(url, handleSuccess, handleError)


  const handleReset = async (e) => {
    e.preventDefault();

    const formData = {
      password: e.target.password.value,
      password_confirmation: e.target.password_confirmation.value,
    };

    await mutate(formData);
  };

 
  return (
    <div className='vh-100' style={{ backgroundImage: `url(${bg})` }}>
      <section className="padding-top-form-change" id="hero1">
        <div className="container h-25">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: "1rem" }}
              >
                <form onSubmit={handleReset} className="card-body p-5">
                  <h3 className="mb-3 text-center">Change Password</h3>

                  <div className="form-outline mb-4">
                    <label className="form-label" for="typeEmailX-2">
                      New Password
                    </label>
                    <input
                      type="password"
                      name='password'
                      id="typeEmailX-2"
                      className="form-control form-control-lg"
                    />
                  </div>

                  <div className="form-outline mb-4">
                    <label className="form-label" for="typeEmailX-2">
                      New Confirm Password
                    </label>
                    <input
                      type="password"
                      name='password_confirmation'
                      id="typeEmailX-2"
                      className="form-control form-control-lg"
                    />
                  </div>

                  <div className="text-center text-lg-start mt-2">
                    <div>
                      <button
                        type="sumbit"
                        className="btn bg-custom-red text-white btn-lg btn-hover"
                        style={{
                          paddingLeft: "2.5rem",
                          paddingRight: "2.5rem",
                        }}
                      >
                        {isPending ? <Spinner /> : 'Change Password'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ResetPassword