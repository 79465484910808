import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo1.png";

const HeaderReviewer = () => {
  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/";
  };
  return (
    <div>
      <header id="header-backend" className="d-flex align-items-center ">
        <div className="container-fluid container-xxl d-flex align-items-center">
          <div id="logo" className="me-auto mt-2">
            {/* <h1>
            <Link to="/">
              CONF<span>CHAIR</span>
            </Link>
          </h1> */}
            <Link to="/" className="scrollto">
              <img src={logo} alt="CONF CHAIR" />
            </Link>
          </div>

          <nav
            id="navbar"
            className="navbar order-last order-lg-0 navbar-backend"
          >
            <ul>
              <li className="dropdown">
                <a href="#">
                  <span>Conference</span> <i className="bi bi-chevron-down"></i>
                </a>
                <ul>
                  <li>
                    <Link to="/recent-roles">Recent Roles</Link>
                  </li>
                  <li>
                    <Link to="/organizer-view">Manage Confrences</Link>
                  </li>
                  <li>
                    <Link to="/welcome">All Confrences</Link>
                  </li>
                </ul>
              </li>
              <button className="btn nav-btn-left nav-btn-mobile btn-log-backend scrollto">
                <Link className="nav-link scrollto" to="/view-paper">
                  View Papers
                </Link>
              </button>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          <div className="dropdown">
            <a
              className="nav-link link-button scrollto"
              href="#"
              role="button"
              id="profileDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                width="36"
                height="36"
                className="profile-image"
                src="https://img.icons8.com/color/48/test-account.png"
                alt="test-account"
              />
            </a>
            <ul className="dropdown-menu" aria-labelledby="profileDropdown">
              <li>
                <Link className="dropdown-item" to="/my-account">
                  My Account
                </Link>
              </li>
              <li>
                <button
                  onClick={handleLogout}
                  className="dropdown-item"
                  type="button"
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </div>
  );
};

export default HeaderReviewer;
