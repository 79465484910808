import React, { useState, useEffect } from "react";
import WelcomeChair from "./WelcomeChair";
import Footer from "../Components/Footer";
import { useParams } from "react-router-dom";
import { getToken } from "../utils/LocalStorage";
import base from "../utils/base";
import toast from "react-hot-toast";
import { useFetchQuery } from "../utils/fetchData";

const Paperwise = () => {


  const [papers, setPapers] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [papersStatus, setPapersStatus] = useState([]);
  const [reviewersStatus, setReviewersStatus] = useState([]);

  const { id } = useParams();

  const url = `${base}/api/v1/submission/getby/conference/${id}`

  const { data, isSuccess, error } = useFetchQuery(url, 'SubmissionUsingConference')

  useEffect(() => {
    if (isSuccess && data) {
      setPapers(data.message);
      setPapersStatus(data.status);
    }
  }, [isSuccess, data, papers, papersStatus]);

  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }


  const url2 = `${base}/api/v1/conference/view/reviewers/${id}`

  const { data: reviewerData, isSuccess: reviewerSuccess, error: reviewerError } = useFetchQuery(url2, 'ConferencveReviewer')

  useEffect(() => {
    if (reviewerSuccess && reviewerData) {
      setReviewers(reviewerData.message);
      setReviewersStatus(reviewerData.status);
    }
  }, [reviewerSuccess, reviewerData, papers, papersStatus]);

  if (reviewerError) {
    toast.error(reviewerError.response.data.message, { duration: 3000 });
  }


  const isReviewerAssigned = (paper, reviewerId) => {
    return Array.isArray(paper.reviewers) && paper.reviewers.some((assignedReviewer) => assignedReviewer.user === reviewerId);
  };



  const toggleReviewerAssignment = async (paper, submissionID, reviewerID, reviewerEmail) => {
    try {
      if (!paper || !paper._id || !Array.isArray(paper.reviewers)) {
        console.error("Invalid paper object:", paper);
        return;
      }

      const isAssigned = isReviewerAssigned(paper, reviewerID);

      const host = `${base}/api/v1/submission/update/reviewers/${submissionID}`;
      const method = "POST"; // Assuming you have a route to update reviewers via a PUT request

      const response = await fetch(host, {
        method,
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: getToken(),
        },
        body: JSON.stringify({
          reviewerEmails: [reviewerEmail],
          operation: isAssigned ? 'remove' : 'add',
        }),
      });

      const result = await response.json();
      // console.log(result);
      window.location.reload()

      if (result.status === "success") {
        // Fetch the updated reviewers separately
        const updatedResponse = await fetch(`${base}/api/v1/submission/getby/conference/${id}`);
        const updatedData = await updatedResponse.json();

        setPapers(updatedData.message || []);
      }
      else {
        toast.error(result.message, { duration: 3000 })
      }
    } catch (error) {
      console.error("Error toggling reviewer assignment:", error);
    }
  };





  return (
    <div>
      <WelcomeChair id={id}/>
      <div className="section-header text-center justify-content-center pt-4 mt-4">
        <h2>Add Reviewers</h2>
        <p>Here you can add reviewers to papers</p>
      </div>
      {papers.map((paper, index) => (
        <div key={index} className="paperwise text-center mt-4 pt-4">
          <div className="text-center">
            <h3 className="bg-border-custom-title d-inline-block col-lg-2 col-md-4 col-6">{paper.title}</h3>
          </div>
          <div className="box d-flex flex-row justify-content-center flex-wrap">
            {reviewers.map((reviewer, reviewerIndex) => (
              <label key={reviewerIndex} className="paper col-4 col-md-4 col-lg-2 mx-1 mt-3 bg-border-custom">
                <input
                  type="checkbox"
                  name="Sanjay"
                  checked={isReviewerAssigned(paper, reviewer._id)}
                  onChange={() => toggleReviewerAssignment(paper, paper._id, reviewer._id, reviewer.email)}
                />
                <span>
                  <h4 className="paper-title pt-2">{reviewer.firstName}</h4>
                  <p className="email pt-2 lowercase">{reviewer.email}</p>
                  {reviewer.reviewerData && reviewer.reviewerData.map((assignedData, index) => (
                    assignedData.conference === id ? 
                    <div key={index} className="d-flex flex-row justify-content-center flex-wrap">
                      <p className="paper-desc m-0 px-1">Assigned: {assignedData.assigned}</p>
                    </div> : 0
                  ))}
                </span>
              </label>
            ))}
          </div>
          <hr className="col-11 mx-auto dark-hr mt-4" />
        </div>
      ))}
      <Footer />
    </div>
  );
};

export default Paperwise;
