import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getToken } from "../utils/LocalStorage";
import Welcome from "./Welcome";
import Footer from "./Footer";
import { useFetchQuery, useMutateQuery } from "../utils/fetchData";
import toast from "react-hot-toast";
import base from "../utils/base";

const MyAccountEdit = () => {

  const navigate = useNavigate()

  const [user, setUser] = useState([]);

  const url = `${base}/api/v1/auth/me`;

  const { data, isSuccess, error } = useFetchQuery(url, "User");

  useEffect(() => {
    if (isSuccess && data) {
      console.log(data);
      setUser(data.data);
    }
  }, [isSuccess, data, user]);

  if (error) {
    toast.error(error.response.data.message, { duration: 3000 });
  }


  const url3 = `${base}/api/v1/auth/update/user`

  const handleSuccess = (data) => {
    toast.success(data.message, { duration: 3000 });
    navigate(`/welcome`);
  };

  const handleError = (submitError) => {
    toast.error(submitError.response.data.message, { duration: 3000 });
  };

  const { mutate, isPending, isError, error: updationError } = useMutateQuery(url3, handleSuccess, handleError)

  const handleUpdate = async (e) => {
    e.preventDefault();

    const formData = {
      firstName: e.target.firstName.value,
      lastName: e.target.lastName.value,
      email: e.target.email.value,
      altEmail: e.target.altEmail.value,
      mobileNumber: e.target.mobileNumber.value,
      altMobileNumber: e.target.altMobileNumber.value,
      organizationName: e.target.organizationName.value,
      city: e.target.city.value,
      state: e.target.state.value,
      country: e.target.country.value,
      pincode: e.target.pincode.value,
      facebookID: e.target.facebookID.value,
      twitterID: e.target.twitterID.value,
      linkedinID: e.target.linkedinID.value,
      googleScholarId: e.target.googleScholarId.value,
      semanticScholarId: e.target.semanticScholarId.value,
      dblpId: e.target.dblpId.value,
      orcidId: e.target.orcidId.value
    };

    await mutate(formData);
  };

  return (
    <div>
      <Welcome />
      <div
        className="information table-responsive table-scroll p-5 m-5 pb-0 mb-0"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "700px" }}
      >
        <div className="d-flex flex-row justify-content-between">
          <h2 className="text-center">My Account</h2>

          <Link to="/my-account-edit">
            <div className="d-flex flex-row">
              <img
                width="20"
                height="20"
                className="d-flex flex-row justify-content-end"
                src="https://img.icons8.com/color/48/edit.png"
                alt="edit"
              />
              <p>Edit</p>
            </div>
          </Link>
        </div>
        <form onSubmit={handleUpdate}>

          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">First Name</th>
                <td scope="row">
                  <input
                    type="text"
                    name="firstName"
                    placeholder={user.firstName}
                  />
                </td>
              </tr>
              <tr>
                <th scope="col">Last Name</th>
                <td scope="row">
                  <input
                    type="text"
                    name="lastName"
                    placeholder={user.lastName}
                  />
                </td>
              </tr>
              <tr>
                <th scope="col">Email</th>
                <td scope="row">
                  <input type="text" name="email" placeholder={user.email} />
                </td>
              </tr>
              <tr>
                <th scope="col">Alternate Email</th>
                <td scope="row">
                  <input
                    type="text"
                    name="altEmail"
                    placeholder={user.altEmail}
                  />
                </td>
              </tr>

              <tr>
                <th scope="col">Mobile Number</th>
                <td scope="row">
                  <input
                    type="text"
                    name="mobileNumber"
                    placeholder={user.mobileNumber}
                  />
                </td>
              </tr>
              <tr>
                <th scope="col">Alternate Mobile Number</th>
                <td scope="row">
                  <input
                    type="text"
                    name="altMobileNumber"
                    placeholder={user.altMobileNumber}
                  />
                </td>
              </tr>

              <tr>
                <th scope="col" className="bg-table">
                  Organization Name
                </th>
                <td scope="row">
                  {" "}
                  <input
                    type="text"
                    name="organizationName"
                    placeholder={user.organizationName}
                  />
                </td>
              </tr>
              <tr>
                <th scope="col">City</th>
                <td scope="row">
                  {" "}
                  <input type="text" name="city" placeholder={user.city} />
                </td>
              </tr>
              <tr>
                <th scope="col">State</th>
                <td scope="row">
                  {" "}
                  <input type="text" name="state" placeholder={user.state} />
                </td>
              </tr>
              <tr>
                <th scope="col">Country</th>
                <td scope="row">
                  {" "}
                  <input type="text" name="country" placeholder={user.country} />
                </td>
              </tr>
              <tr>
                <th scope="col">Pincode</th>
                <td scope="row">
                  {" "}
                  <input type="text" name="pincode" placeholder={user.pincode} />
                </td>
              </tr>
              <tr>
                <th scope="col">Facebook Id</th>
                <td scope="row">
                  {" "}
                  <input
                    type="text"
                    name="facebookID"
                    placeholder={user.facebookID}
                  />
                </td>
              </tr>
              <tr>
                <th scope="col">Linkedin Id</th>
                <td scope="row">
                  {" "}
                  <input
                    type="text"
                    name="linkedinID"
                    placeholder={user.linkedinID}
                  />
                </td>
              </tr>
              <tr>
                <th scope="col">Twitter Id</th>
                <td scope="row">
                  {" "}
                  <input
                    type="text"
                    name="twitterID"
                    placeholder={user.twitterID}
                  />
                </td>
              </tr>
              <tr>
                <th scope="col">Google Scholar Id</th>
                <td scope="row">
                  {" "}
                  <input
                    type="text"
                    name="googleScholarId"
                    placeholder={user.googleScholarId}
                  />
                </td>
              </tr>
              <tr>
                <th scope="col">Semantic Scholar Id</th>
                <td scope="row">
                  {" "}
                  <input
                    type="text"
                    name="semanticScholarId"
                    placeholder={user.semanticScholarId}
                  />
                </td>
              </tr>
              <tr>
                <th scope="col">DBLP Id</th>
                <td scope="row">
                  <input
                    type="text"
                    name="dblpId"
                    placeholder={user.dblpId}
                  />
                </td>
              </tr>
              <tr>
                <th scope="col">ORCID Id</th>
                <td scope="row">
                  {" "}
                  <input
                    type="text"
                    name="orcidId"
                    placeholder={user.orcidId}
                  />
                </td>
              </tr>
            </thead>
          </table>
          <button className="btn btn-custom1 btn-block" type="submit">Update</button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default MyAccountEdit;
